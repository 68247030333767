import * as axios from 'axios';
import { RoleInterface } from './modules/users/Roles';
import { FilterColumnOption } from './components/FilterColumn';

export type TwoFactorMethod = 'email' | 'authenticator';
export type Status = 'ACTIVE' | 'COMPLETED';

export interface HydraCollectionResponse<T> extends axios.AxiosResponse {
  data: {
    'hydra:member': T[];
    'hydra:totalItems': number;
  };
}

export interface DataCollection<T> {
  items: T[];
  totalItems: number;
  perPage?: number;
}

export interface CollectionResponse<T> extends axios.AxiosResponse {
  data: DataCollection<T>;
}

export interface Repository<T> {
  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
  ): Promise<
    HydraCollectionResponse<T> | CollectionResponse<T> | DataCollection<T>
  >;

  delete?(id: string): Promise<axios.AxiosResponse>;
}

export interface TokenResponse extends axios.AxiosResponse {
  data: {
    token: string;
    ttl: number;
    refresh_token: string;
    refresh_token_ttl: number;
  };
}

export interface Place {
  id: string;
  name: string;
  code: string;
}

export interface Municipality {
  id: string;
  name: string;
  code: string;
}

export interface User {
  id?: string;
  roles: (keyof RoleInterface)[];
  email: string;
  firstName: string;
  insertion: string | null;
  lastName: string;
  active: boolean;
  fullName?: string;
  fullNameLastNameFirst?: string;
  avatar?: string;
  isImitating?: boolean;
  isImitatingAdmin?: boolean;
  authMethod?: TwoFactorMethod;
  fund: MinimalFund | null;
}

export interface Project {
  id: string;
  title: string;
  description: string;
  shortDescription: string;
  startDate?: Date | null;
  endDate?: Date | null;
  concept: boolean;
  status: Status;
  finance: Finance;
  fund: Fund;
  categories: Category[];
  attachments: File[];
  evaluation: string;
  evaluationPublic: boolean;
  logo?: File;
  created: string;
  updated: string;
}

export interface Category {
  id: string;
  name: string;
  description: string | null;
  projects?: Project[];
  created: string;
  updated: string;
}

export interface CategoryDTO {
  name: string;
  description: string | null;
}

export interface SaveProjectDTO {
  id?: string;
  title: string;
  description: string;
  shortDescription: string;
  startDate?: Date | null;
  endDate?: Date | null;
  concept: boolean;
  status: Status;
  finance: FinanceDTO;
  fundId: string;
  categories: string[];
  // the attachments are used in the UI,
  // whereas the attachmentIds are used by the backend
  attachments: File[];
  attachmentIds: string[];
  evaluation: string;
  evaluationPublic: boolean;
  // the logo is used in the UI,
  // whereas the logoId is used by the backend
  logo?: File;
  logoId: string;
}

export interface Fund {
  id: string;
  name: string;
  websiteUrl?: string;
  manager?: MinimalUser | null;
  places: Place[];
  municipalities: Municipality[];
  phoneNumber?: string;
  email?: string;
  logo?: File;
}

export interface FundDto {
  id?: string;
  name: string;
  websiteUrl: string;
  managerId: string;
  // place and municipality objects are used in the UI,
  // whereas the codes are used by the backend
  places: Place[];
  placeCodes: string[];
  municipalities: Municipality[];
  municipalityCodes: string[];
  phoneNumber: string;
  email: string;
  // the logo is used in the UI,
  // whereas the logoId is used by the backend
  logo?: File;
  logoId: string;
}

export interface MinimalFund {
  id: string;
  name: string;
}

export interface Finance {
  fundCosts?: number;
  generalPractitionerCosts?: number;
  description?: string;
  public: boolean;
}

export interface FinanceDTO {
  fundCosts: number | null;
  generalPractitionerCosts: number | null;
  description: string;
  public: boolean;
}

export interface Address {
  city: string;
  postCode: string;
  street: string;
  houseNumber: string;
}

export interface MinimalUser {
  id: string;
  email: string;
  fullName: string;
  fullNameLastNameFirst: string;
  fundId?: string;
  avatar: string;
}

export type assignableRole = 'admin' | 'centralManager' | 'fundUser';

export interface UserDTO {
  id?: string;
  email: string;
  firstName: string;
  insertion: string | null;
  lastName: string;
  fundId: string | null;
  initialRole: assignableRole | null;
}

export interface ApiFilterCriteria {
  query?: string | null;
  order?: {
    field: string;
    order: 'asc' | 'desc';
  }[];
  filters?: { [key: string]: string | number | boolean | FilterColumnOption[] };
}

export interface EventLog {
  id: string;
  type: string;
  userId: string | null;
  context: {};
  created: string;
}

export interface File {
  id: string;
  name: string;
  size: number;
  mimeType: string;
  created: string;
  read: boolean;
  deletable: boolean;
}

export interface Notification {
  id: string;
  type: string;
  message: string;
  references: { [key: string]: string };
  read: boolean;
  created: string;
}

export interface NotificationPreferences {
  optOutMailings: boolean;
  optOutTypes: string[];
}

export const fundToMinimalFund = (fund: Fund) =>
  ({
    id: fund.id,
    name: fund.name,
  } as MinimalFund);

export const userToMinimalUser = (user: User) =>
  ({
    id: user.id,
    email: user.email,
    fullName: user.fullName,
    fullNameLastNameFirst: user.fullNameLastNameFirst,
    avatar: user.avatar,
  } as MinimalUser);

export const projectToCreateProjectDTO = (project: Project) =>
  ({
    id: project.id,
    title: project.title,
    description: project.description,
    shortDescription: project.shortDescription,
    startDate: project.startDate,
    endDate: project.endDate,
    concept: project.concept,
    status: project.status,
    finance: project.finance,
    fundId: project.fund.id,
    categories: project.categories.map((c) => c.id),
    attachments: project.attachments,
    attachmentIds: project.attachments.map((p) => p.id),
    evaluation: project.evaluation,
    evaluationPublic: project.evaluationPublic,
    logo: project?.logo,
    logoId: project?.logo?.id || '',
  } as SaveProjectDTO);

export const userToUserDTO = (user: User): UserDTO =>
  ({
    id: user.id,
    email: user.email,
    firstName: user.firstName,
    insertion: user.insertion || '',
    lastName: user.lastName,
    fundId: user.fund?.id,
    // role field only relevant for new users
    initialRole: null,
  } as UserDTO);

export interface CRUDPermission {
  canCreate: boolean;
  canEdit: boolean;
  canView: boolean;
  canDelete: boolean;
}
