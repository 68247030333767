import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Button,
  FormLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@mui/styles';
// eslint-disable-next-line import/no-unresolved
import { Flow as IFlow } from 'flowjs';
import { useSnackbar } from 'notistack';
import {
  ApiFilterCriteria,
  File as FileInterface,
  FundDto,
  MinimalUser,
  Municipality,
  Place,
  User,
  userToMinimalUser,
} from '../../types';
import PlaceAutocomplete from '../../components/PlaceAutocomplete';
import FileUpload from '../../components/file/FileUpload';
import MunicipalityAutocomplete from '../../components/MunicipalityAutocomplete';
import { createFlow, uniqueEntries } from '../../utils/common';
import UserRepository from '../users/UserRepository';
import FundRepository from './FundRepository';
import useFundPermissions from './fund-permissions';
import FileChip from '../../components/file/FileChip';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 600,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  field: {
    marginTop: theme.spacing(2),
  },
}));

type FundFormProps = {
  fund?: FundDto;
};

const FundForm = (props: FundFormProps) => {
  const classes = useStyles();

  const { fund: existingFund } = props;

  const notifications = useSnackbar();
  const history = useHistory();

  const [users, setUsers] = useState<MinimalUser[]>([]);
  const [logo, setLogo] = useState<File | null>(null);

  const fundRepository = new FundRepository();

  const [fund, setFund] = useState<FundDto>(
    existingFund || {
      name: '',
      websiteUrl: '',
      managerId: '',
      places: [],
      placeCodes: [],
      municipalities: [],
      municipalityCodes: [],
      phoneNumber: '',
      email: '',
      logoId: '',
    },
  );

  const { canEdit, canEditManager } = useFundPermissions(fund?.id);

  const handleManagerChange = (event: SelectChangeEvent<unknown>) => {
    setFund({
      ...fund,
      managerId: event.target.value as string,
    });
  };

  const handlePlacesChange = (places: Place[]) => {
    setFund({
      ...fund,
      places,
      placeCodes: places
        .map((place: Place) => place.code)
        .filter(uniqueEntries),
    });
  };

  const handleMunicipalitiesChange = (municipalities: Municipality[]) => {
    setFund({
      ...fund,
      municipalities,
      municipalityCodes: municipalities
        .map((municipality: Municipality) => municipality.code)
        .filter(uniqueEntries),
    });
  };

  const handleLogoRemoval = () => {
    setFund((oldState: FundDto) => {
      return { ...oldState, logo: undefined, logoId: '' };
    });
  };

  const handleLogoChange = (files: File[]) => {
    if (files.length > 0) {
      setLogo(files[0]);
    }
  };

  const doSubmit = (fund: FundDto) => {
    const promise = fund?.id
      ? fundRepository.update(fund)
      : fundRepository.create(fund);

    promise
      .then(() => {
        history.push('/fondsen');
        notifications.enqueueSnackbar(
          `Fonds is succesvol ${fund?.id ? 'gewijzigd' : 'aangemaakt'}!`,
          { variant: 'success' },
        );
      })
      .catch(() =>
        notifications.enqueueSnackbar(
          `Er is iets fout gegaan bij het ${
            fund?.id ? 'wijzigen' : 'aanmaken'
          } van het fonds.`,
          { variant: 'error' },
        ),
      );
  };

  const submit = (
    e: FormEvent<HTMLFormElement>,
    fund: FundDto,
    logo: File | null,
  ) => {
    e.preventDefault();

    let logoFileId: string;

    if (logo) {
      const flow: IFlow = createFlow();
      flow.addFile(logo);

      flow.on('fileSuccess', (flowFile, message) => {
        const response: FileInterface = JSON.parse(message);
        logoFileId = response.id;
      });
      flow.on('complete', () => doSubmit({ ...fund, logoId: logoFileId }));

      flow.upload();
    } else {
      doSubmit(fund);
    }
  };

  useEffect(() => {
    if (!fund?.id) {
      return;
    }

    const criteria: ApiFilterCriteria = {
      filters: {
        fundId: fund.id,
      },
    };

    new UserRepository().findBy(criteria, 1, 1e6).then((res) => {
      const users: Array<MinimalUser> = [];
      res.data.items.forEach((user: User) => {
        users.push(userToMinimalUser(user));
      });
      setUsers(users);
    });
  }, []);

  // go back if you're not allowed to edit, e.g. if a fund manager tries to edit the wrong fund
  if (!canEdit) {
    history.push('/fondsen');
  }

  return (
    <Paper className={classes.container}>
      <form autoComplete="off" onSubmit={(e) => submit(e, fund, logo)}>
        <Box mb={3}>
          <TextField
            label="Naam"
            className={classes.field}
            value={fund.name}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFund({ ...fund, name: e.target.value });
            }}
            fullWidth
            required
          />
          {/* TODO write validation */}
          <TextField
            label="Website URL"
            type="url"
            className={classes.field}
            placeholder="https://www.mijnwebsite.nl"
            value={fund.websiteUrl}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFund({ ...fund, websiteUrl: e.target.value });
            }}
            fullWidth
          />
          <TextField
            label="Email"
            type="email"
            placeholder="email@adres.nl"
            className={classes.field}
            value={fund.email}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFund({ ...fund, email: e.target.value });
            }}
            fullWidth
          />
          <TextField
            label="Telefoonnummer"
            type="text"
            placeholder="06-12345678"
            className={classes.field}
            value={fund.phoneNumber}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFund({ ...fund, phoneNumber: e.target.value });
            }}
            fullWidth
          />
          {fund?.id && canEditManager && (
            <Select
              value={fund?.managerId || ''}
              className={classes.field}
              onChange={handleManagerChange}
              displayEmpty
              style={{ width: 300 }}
              MenuProps={{
                elevation: 1,
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                transformOrigin: { vertical: 'top', horizontal: 'center' },
              }}
            >
              <MenuItem value="">
                <em>Selecteer een manager...</em>
              </MenuItem>
              {users.map((user) => (
                <MenuItem value={user.id}>{user.fullName}</MenuItem>
              ))}
            </Select>
          )}
        </Box>
        <Box mt={1}>
          <PlaceAutocomplete
            initialPlaces={fund.places}
            onChange={handlePlacesChange}
          />
        </Box>
        <Box mt={1}>
          <MunicipalityAutocomplete
            initialMunicipalities={fund.municipalities}
            onChange={handleMunicipalitiesChange}
          />
        </Box>
        <Box mt={2}>
          <FormLabel>Logo</FormLabel>
          {fund?.logo ? (
            <Box>
              <FileChip file={fund.logo} onDelete={handleLogoRemoval} />
            </Box>
          ) : (
            <FileUpload onChange={handleLogoChange} multiple={false} images />
          )}
        </Box>
        <Box mt={4}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            startIcon={<FontAwesomeIcon icon={['fad', 'save']} />}
          >
            Opslaan
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default FundForm;
