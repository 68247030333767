import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface TabPanelProps {
  id?: string;
  children?: React.ReactNode;
  index: any;
  value: any;
  className?: string;
}

const useStyles = makeStyles(() => ({
  tabpanel: {
    boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.09)',
  },
}));

const TabPanel = (props: TabPanelProps) => {
  const classes = useStyles();
  const { children, value, index, className } = props;

  const id = props.id !== undefined ? props.id : `simple-tabpanel-${index}`;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={id}
      aria-labelledby={`simple-tab-${index}`}
      className={`${classes.tabpanel} ${className || ''}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
