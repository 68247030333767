import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import PageContainer from '../../components/PageContainer';
import ProjectRepository from './ProjectRepository';
import { Category, Project } from '../../types';
import SafeHtml from '../../components/SafeHtml';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  label: {
    fontWeight: 700,
    width: '30%',
  },
  content: {
    width: '70%',
  },
  tableTitleBox: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  tableTitle: {
    fontSize: theme.typography.h5.fontSize,
  },
  neverLogin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const ViewProject = () => {
  const classes = useStyles();
  const repository = new ProjectRepository();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [project, setProject] = useState<Project | null>(null);

  useEffect(() => {
    repository
      .find(id)
      .then((res) => setProject(res.data))
      .catch(() => history.push('/projecten'));
  }, []);

  return (
    <div>
      {project && (
        <PageContainer title="Projectinformatie">
          <Paper className={classes.container}>
            <TableContainer component={Paper}>
              <Box className={classes.tableTitleBox}>
                <h2 className={classes.tableTitle}>Algemene informatie</h2>
              </Box>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.label}>Titel</TableCell>
                    <TableCell className={classes.content}>
                      {project.title ? project.title : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.label}>
                      Samenvatting
                    </TableCell>
                    <TableCell className={classes.content}>
                      {project.shortDescription}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.label}>
                      Beschrijving
                    </TableCell>
                    <TableCell className={classes.content}>
                      <SafeHtml
                        html={project.description ? project.description : '-'}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.label}>Fonds</TableCell>
                    <TableCell className={classes.content}>
                      {project.fund ? project.fund.name : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.label}>Categorieën</TableCell>
                    <TableCell className={classes.content}>
                      {project.categories
                        .map((category: Category) => category.name)
                        .join(', ')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.label}>Startdatum</TableCell>
                    <TableCell className={classes.content}>
                      {project.startDate
                        ? new Date(project.startDate).toLocaleDateString()
                        : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.label}>Einddatum</TableCell>
                    <TableCell className={classes.content}>
                      {project.endDate
                        ? new Date(project.endDate).toLocaleDateString()
                        : '-'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Box className={classes.tableTitleBox}>
                <h2 className={classes.tableTitle}>Fondsinformatie</h2>
              </Box>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.label}>Naam</TableCell>
                    <TableCell className={classes.content}>
                      {project.fund.name ? project.fund.name : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.label}>Website</TableCell>
                    <TableCell className={classes.content}>
                      {project.fund.websiteUrl ? project.fund.websiteUrl : '-'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Box className={classes.tableTitleBox}>
                <h2 className={classes.tableTitle}>Financiële informatie</h2>
              </Box>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.label}>
                      Beschrijving
                    </TableCell>
                    <TableCell className={classes.content}>
                      <SafeHtml
                        html={
                          project.finance.description
                            ? project.finance.description
                            : '-'
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.label}>
                      Projectkosten
                    </TableCell>
                    <TableCell className={classes.content}>
                      {project.finance.fundCosts
                        ? `€ ${project.finance.fundCosts},-`
                        : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.label}>
                      Kosten voor de huisarts
                    </TableCell>
                    <TableCell className={classes.content}>
                      {project.finance.generalPractitionerCosts
                        ? `€ ${project.finance.generalPractitionerCosts},-`
                        : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.label}>
                      Openbaar beschikbaar
                    </TableCell>
                    <TableCell className={classes.content}>
                      {project.finance.public ? 'Ja' : 'Nee'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Box className={classes.tableTitleBox}>
                <h2 className={classes.tableTitle}>Evaluatie</h2>
              </Box>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.label}>
                      Beschrijving
                    </TableCell>
                    <TableCell className={classes.content}>
                      <SafeHtml
                        html={project.evaluation ? project.evaluation : '-'}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.label}>
                      Openbaar beschikbaar
                    </TableCell>
                    <TableCell className={classes.content}>
                      {project.evaluationPublic ? 'Ja' : 'Nee'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </PageContainer>
      )}
    </div>
  );
};

export default ViewProject;
