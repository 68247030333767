import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@mui/lab';
import { TwoFactorMethod, User } from '../../types';
import UserRepository from './UserRepository';
import {
  fulfillTwoFactorConfigurationRequest,
  loginUser,
  refreshAccount,
} from '../../actions';
import { UserState } from '../../reducers/user';
import AppContext from '../../AppContext';

const useStyles = makeStyles(() => ({
  captionLink: {
    margin: '0 0.25rem',
  },
}));

interface TwoFactorConfigurationProps {
  user: User;
  defaultMethod?: TwoFactorMethod;
}

const TwoFactorConfiguration = (props: TwoFactorConfigurationProps) => {
  const { user } = props;

  const classes = useStyles();
  const notifications = useSnackbar();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(
    (selector: { user: UserState }) => selector.user.isLoggedIn,
  );
  const { roleViewManager } = useContext(AppContext);
  const [method, setMethod] = useState<TwoFactorMethod | null>(
    user.authMethod || props.defaultMethod || null,
  );
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [twoFactorCode, setTwoFactorCode] = useState<string | null>(null);
  const [error, setError] = useState<'invalid_2fa_code' | null>(null);

  const handleMethodChange = (event: SelectChangeEvent<unknown>) => {
    setMethod(event.target.value as TwoFactorMethod);
  };

  const doSave = (method: TwoFactorMethod | null) => {
    new UserRepository()
      .updateTwoFactorMethod(user, method, twoFactorCode)
      .then(() => {
        dispatch(
          refreshAccount(() => {
            dispatch(loginUser());
            dispatch(fulfillTwoFactorConfigurationRequest());
          }),
        );
        notifications.enqueueSnackbar(
          'De methode voor twee-factor-authenticatie is succesvol gewijzigd!',
          { variant: 'success' },
        );
      })
      .catch((err) => {
        if (err.response.data.error) {
          setError(err.response.data.error);
        }

        notifications.enqueueSnackbar(
          'Fout bij het wijzigen van de methode voor twee-factor-authenticatie!',
          { variant: 'error' },
        );
      });
  };

  const handleSave = () => {
    if (!method) {
      return;
    }

    doSave(method);
  };

  const handleReset = () => {
    doSave(null);
  };

  const handleTwoFactorCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTwoFactorCode(e.target.value);
  };

  const canSubmit = useMemo(() => {
    if (!method) {
      return false;
    }

    if (method !== 'authenticator') {
      return true;
    }

    return twoFactorCode && twoFactorCode.length === 6;
  }, [twoFactorCode, user, method]);

  useEffect(() => {
    (async () => {
      const response = await new UserRepository().getTwoFactorQrCode();
      setQrCode(response.data.data);
    })();
  }, []);

  return (
    <Box>
      <Box mb={1}>
        <FormLabel>Methode</FormLabel>
      </Box>
      <Select
        value={method ?? ''}
        onChange={handleMethodChange}
        displayEmpty
        style={{ width: 300 }}
        MenuProps={{
          elevation: 1,
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
        }}
      >
        <MenuItem value="">
          <em>Selecteer een methode...</em>
        </MenuItem>
        <MenuItem value="email">E-mail</MenuItem>
        <MenuItem value="authenticator">Authenticator</MenuItem>
      </Select>
      <Box mt={2}>
        {method === 'email' && (
          <>
            {!isLoggedIn && (
              <Typography variant="body1">
                {`Vanaf de eerstvolgende keer inloggen wordt een 6-cijferige verificatiecode gestuurd naar ${user.email}.`}
              </Typography>
            )}
            {isLoggedIn && (
              <>
                <Typography variant="body1">
                  {`Wij versturen bij het inloggen een 6-cijferige verificatiecode naar ${user.email}.`}
                </Typography>
                <Typography variant="caption">
                  Wil je je mail adres wijzigen, wijzig dan je gebruikersnaam
                  onder tabblad profiel en klik op opslaan.
                </Typography>
              </>
            )}
          </>
        )}
        {method === 'authenticator' && user.authMethod !== 'authenticator' && (
          <>
            <Typography variant="body1">
              Scan de onderstaande QR code in je authenticator app.
              <Box width="100%">
                {qrCode && <img src={qrCode} alt="QR-code" />}
              </Box>
              <Box mt={1}>
                <Typography variant="caption">
                  Voor alle type mobiele telefoons worden authenticator apps
                  aangeboden. Heb je nog geen authenticator app dan is de
                  <a
                    href="https://support.google.com/accounts/answer/1066447?hl=nl&co=GENIE.Platform%3DiOS"
                    target="_blank"
                    rel="noreferrer noopener"
                    className={classes.captionLink}
                  >
                    Google
                  </a>
                  of
                  <a
                    // eslint-disable-next-line max-len
                    href="https://support.microsoft.com/nl-nl/topic/microsoft-authenticator-gebruiken-met-microsoft-365-1412611f-ad8d-43ab-807c-7965e5155411"
                    target="_blank"
                    rel="noreferrer noopener"
                    className={classes.captionLink}
                  >
                    Microsoft
                  </a>
                  authenticator app een optie die je kunt gebruiken. Downloaden
                  kan via de appstore vanaf de betreffende mobiele telefoon. Er
                  zijn ook andere apps beschikbaar.
                </Typography>
              </Box>
              <Box mt={2} mb={2}>
                <FormLabel>Voer hier de code in:</FormLabel>
                <Box mt={1}>
                  <TextField
                    onChange={handleTwoFactorCodeChange}
                    value={twoFactorCode}
                  />
                </Box>
                {error === 'invalid_2fa_code' && (
                  <Box mt={2}>
                    <Alert severity="warning">
                      De opgegeven code is onjuist.
                    </Alert>
                  </Box>
                )}
              </Box>
            </Typography>
          </>
        )}
      </Box>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              startIcon={<FontAwesomeIcon icon={['fad', 'save']} />}
              onClick={handleSave}
              disabled={!canSubmit}
            >
              Opslaan
            </Button>
          </Grid>
          {(roleViewManager.isAdminView() ||
            roleViewManager.isCentralManagerView()) && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<FontAwesomeIcon icon={['fal', 'undo']} />}
                onClick={handleReset}
                disabled={method === null}
              >
                Resetten
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default TwoFactorConfiguration;
