import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSnackbar } from 'notistack';
import UserImitator from '../UserImitator';
import RoleViewManager, { RoleView } from '../../../RoleViewManager';
import { User } from '../../../types';
import AppContext from '../../../AppContext';
import { UserState } from '../../../reducers/user';

interface UserImitateButtonProps {
  user: User;
  className?: string;
}

const UserImitateButton = ({
  user,
  className = '',
}: UserImitateButtonProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const notifications = useSnackbar();
  const [roleMenuAnchorEl, setRoleMenuAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const isMenuOpen = Boolean(roleMenuAnchorEl);
  const isImitating = useSelector((selector: { user: UserState }) =>
    Boolean(selector.user.account && selector.user.account.isImitating),
  );
  const { roleViewManager, localStore } = useContext(AppContext);
  const userViewManager = new RoleViewManager(user);

  const handleRoleMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    setRoleMenuAnchorEl(e.currentTarget);
  };

  const handleRoleMenuClose = () => {
    setRoleMenuAnchorEl(null);
  };

  const doImitate = (role?: RoleView) => {
    new UserImitator(dispatch, roleViewManager, localStore, isImitating)
      .imitate(user.email!, role)
      .then(() => {
        history.push('/');
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          `Kan de gebruiker "${user.fullNameLastNameFirst}" niet imiteren.`,
          { variant: 'error' },
        );
      });
  };

  const handleImitate = (e: React.MouseEvent<HTMLElement>) => {
    if (userViewManager.getViews().length > 1) {
      handleRoleMenuOpen(e);
      return;
    }

    doImitate();
  };

  return (
    <>
      <Menu
        anchorEl={roleMenuAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        id="primary-account-menu"
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        style={{ zIndex: 1401, top: 40 }}
        onClose={handleRoleMenuClose}
        elevation={1}
      >
        {userViewManager.getViews().map((view) => (
          <MenuItem onClick={() => doImitate(view)}>
            {userViewManager.getViewLabel(view)}
          </MenuItem>
        ))}
      </Menu>
      <Tooltip title="Gebruiker overnemen">
        <IconButton size="small" onClick={handleImitate} className={className}>
          <FontAwesomeIcon icon={['fal', 'ghost']} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default UserImitateButton;
