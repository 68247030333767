import React from 'react';
import { IconButton, Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { red } from '@mui/material/colors';
import FileIcon from './FileIcon';

interface FileListProps {
  files: File[];
  invalidFiles?: File[];
  onRemove?: (index: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  files: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  fileContainer: {
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  file: {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    paddingRight: 40,
    '&:hover': {
      '& $fileDeleteIcon': {
        display: 'inline-block',
        opacity: 1,
      },
    },
  },
  fileDeleteIcon: {
    position: 'absolute',
    top: 3,
    right: 0,
    display: 'none',
    opacity: 0,
    width: 32,
    height: 32,
    color: red[400],
    transition: theme.transitions.create('opacity'),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  preview: {
    marginRight: theme.spacing(2),
  },
  previewImage: {
    width: 32,
    height: 32,
  },
  invalidIcon: {
    color: red[400],
    marginRight: theme.spacing(2),
  },
}));

const FileList = (props: FileListProps) => {
  const { files, invalidFiles, onRemove } = props;
  const classes = useStyles();

  return (
    <div className={classes.files}>
      {files.map((file, index) => (
        <div key={file.name} className={classes.fileContainer}>
          <div className={classes.file}>
            {invalidFiles &&
              invalidFiles.find(
                (invalidFile) =>
                  invalidFile.name === file.name &&
                  invalidFile.size === file.size,
              ) && (
                <Tooltip title="Dit bestand wordt niet ondersteund">
                  <FontAwesomeIcon
                    icon={['fal', 'exclamation-circle']}
                    className={classes.invalidIcon}
                    style={{ width: 24, height: 24 }}
                  />
                </Tooltip>
              )}
            <div className={classes.preview}>
              <FileIcon file={file} />
            </div>
            <div>{file.name}</div>
            {onRemove && (
              <IconButton
                onClick={() => onRemove(index)}
                size="small"
                className={classes.fileDeleteIcon}
              >
                <FontAwesomeIcon icon={['fal', 'times']} />
              </IconButton>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FileList;
