import React, { MouseEvent, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Menu,
  Theme,
  Toolbar,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import { orange } from '@mui/material/colors';

import { ReactComponent as Logo } from './assets/images/logo.svg';
import settings from './config/settings';
import UserImitator from './modules/users/UserImitator';
import UserAuthenticator from './api/UserAuthenticator';
import Avatar from './modules/users/Avatar';
import AppContext from './AppContext';
import { UserState } from './reducers/user';
import { RoleView } from './RoleViewManager';
import { colors } from './config/theme';
import decoration from './assets/images/TopBarDecoration.svg';
import { CATEGORY_OVERVIEW } from './modules/projects';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    color: colors.darkGrey,
    background: `url(${decoration})`,
    backgroundRepeat: 'no-repeat',
    backgroundWidth: '300px',
    backgroundPosition: 'right',
    backgroundColor: colors.white,
    boxShadow: theme.shadows[3],
    [theme.breakpoints.down('sm')]: {
      background: colors.white,
    },
  },
  button: {
    alignContent: 'center',
    color: colors.darkGrey,
  },
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  menuButton: {
    marginRight: theme.spacing(1),
    fontSize: 23,
  },
  menu: {
    width: 250,
    marginTop: 80,
  },
  menuIcon: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: theme.spacing(4),
  },
  titleLink: {
    color: colors.darkGrey,
    '&:hover': {
      color: colors.darkerGrey,
    },
  },
  buttonLink: {
    fontWeight: 600,
    textTransform: 'none',
    color: colors.darkGrey,
    marginRight: theme.spacing(1),
    '&:hover': {
      color: colors.darkerGrey,
      background: colors.lightestGrey,
    },
  },
  container: {
    position: 'relative',
  },
  toolbar: {
    zIndex: 1,
    height: settings.header.height,
    padding: 0,
  },
  toolbarContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  toolbarLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbarRight: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbarRightGrid: {
    width: 'auto',
  },
  profileMenu: {
    zIndex: 1401,
  },
  imitationBar: {
    width: '100%',
    height: 20,
    background: orange[800],
    textAlign: 'center',
    fontSize: 12,
    color: '#FFF',
  },
  link: {
    color: colors.darkGrey,
    textDecoration: 'underline',
    '&:hover': {
      color: colors.darkerGrey,
    },
  },
  userName: {
    marginLeft: theme.spacing(2),
    textAlign: 'left',
    lineHeight: '18px',
    textTransform: 'none',
  },
  activeStateBar: {
    position: 'absolute',
    opacity: 0,
    left: 'auto',
    bottom: 0,
    width: 0,
    height: 4,
    background: colors.shamrock,
    transition: theme.transitions.create(['left', 'width']),
  },
  account: {
    marginRight: '100px',
  },
}));

function Navigation() {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { isLoggedIn, isImitating, account } = useSelector(
    (selector: { user: UserState }) => ({
      isLoggedIn: selector.user.isLoggedIn,
      isImitating: Boolean(
        selector.user.account && selector.user.account.isImitating,
      ),
      account: selector.user.account,
    }),
  );
  const dispatch = useDispatch();
  const { roleViewManager, localStore } = useContext(AppContext);

  useEffect(() => {
    const activeStateBar = document.querySelector<HTMLDivElement>(
      `.${classes.activeStateBar}`,
    );
    const activeItem = document.querySelector<HTMLLinkElement>(
      `.${classes.toolbar} .active`,
    );

    if (!activeItem) {
      activeStateBar!.style.opacity = '0';
      return;
    }

    activeStateBar!.style.opacity = '1';
    activeStateBar!.style.left = `${activeItem.offsetLeft}px`;
    activeStateBar!.style.width = `${
      activeItem.getBoundingClientRect().width
    }px`;
  }, [location]);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = (e: MouseEvent) => {
    e.preventDefault();
    new UserAuthenticator(dispatch).logout(() => {
      handleMenuClose();
      history.push('/');
    });
  };

  const handleMyAccount = () => {
    history.push('/account');
    handleMenuClose();
  };

  const changeView = async (view: RoleView) => {
    await roleViewManager.setCurrentView(view);
    history.push('/');
    handleMenuClose();
  };

  const stopImitating = () => {
    new UserImitator(
      dispatch,
      roleViewManager,
      localStore,
      isImitating,
    ).stopImitating(() => {
      history.push('/gebruikers');
    });
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id="primary-account-menu"
      className={classes.profileMenu}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      style={{ zIndex: 1401, top: 40 }}
      onClose={handleMenuClose}
      elevation={1}
    >
      <MenuItem onClick={handleMyAccount}>Profiel</MenuItem>
      {roleViewManager.getViews().length > 1 &&
        roleViewManager
          .getViews()
          .filter((view) => view !== roleViewManager.getCurrentView())
          .map((view) => (
            <MenuItem onClick={() => changeView(view)}>
              {`${roleViewManager.getViewLabel(view)} weergave`}
            </MenuItem>
          ))}
      {account && isImitating && (
        <MenuItem onClick={stopImitating}>Stop overnemen</MenuItem>
      )}
      <MenuItem onClick={handleLogout}>Uitloggen</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Container className={classes.container}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.activeStateBar} />
            <Box display="flex" alignItems="center" mr={6}>
              <Link to="/" className={classes.titleLink}>
                <Logo height={85} width="auto" />
              </Link>
            </Box>
            <Box className={classes.toolbarContent}>
              <Box className={classes.toolbarLeft}>
                {account && isLoggedIn && (
                  <Box>
                    {(roleViewManager.isAdminView() ||
                      roleViewManager.isCentralManagerView() ||
                      roleViewManager.isFundManagerView()) && (
                      <Button
                        component={Link}
                        to="/gebruikers"
                        color="inherit"
                        className={`${classes.buttonLink} ${
                          /\/gebruikers.*/.test(location.pathname)
                            ? 'active'
                            : ''
                        }`}
                      >
                        Gebruikers
                      </Button>
                    )}
                    <Button
                      component={Link}
                      to="/fondsen"
                      color="inherit"
                      className={`${classes.buttonLink} ${
                        /\/fondsen.*/.test(location.pathname) ? 'active' : ''
                      }`}
                    >
                      Fondsen
                    </Button>
                    <Button
                      component={Link}
                      to="/projecten"
                      color="inherit"
                      className={`${classes.buttonLink} ${
                        /\/projecten.*/.test(location.pathname) ? 'active' : ''
                      }`}
                    >
                      Projecten
                    </Button>
                    {roleViewManager.hasRole([
                      'ROLE_ADMIN',
                      'ROLE_CENTRAL_MANAGER',
                    ]) && (
                      <Button
                        component={Link}
                        to={CATEGORY_OVERVIEW}
                        color="inherit"
                        className={`${classes.buttonLink} ${
                          /\/categorieen.*/.test(location.pathname)
                            ? 'active'
                            : ''
                        }`}
                      >
                        Categorieën
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
              <Box className={classes.toolbarRight}>
                <Grid
                  container
                  spacing={2}
                  alignContent="center"
                  className={classes.toolbarRightGrid}
                >
                  {isLoggedIn && account && (
                    <Grid item className={classes.account}>
                      <Button onClick={handleProfileMenuOpen} color="inherit">
                        <Avatar user={account} imitating={isImitating} />
                        <div className={classes.userName}>
                          <div>{account.fullName}</div>
                          {roleViewManager.getViewLabel()}
                        </div>
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {renderMenu}
    </div>
  );
}

export default Navigation;
