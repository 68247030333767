import React from 'react';
import { Box, Chip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { File } from '../../types';
import FileIcon from './FileIcon';
import { getFileURL } from '../../utils/common';

interface FileChipProps {
  file: File;
  onDelete?: (file: File) => void;
}

const FileChip = (props: FileChipProps) => {
  const { file, onDelete } = props;

  const handleDelete = () => {
    if (onDelete) {
      onDelete(file);
    }
  };

  const handleDownloadFile = () => {
    window.open(getFileURL(file), '_blank', 'noreferrer,noopener');
  };

  const deletable = file.deletable && onDelete;

  let deleteCallback;

  if (deletable) {
    deleteCallback = handleDelete;
  }

  const deleteIcon = (
    <Box
      ml={2}
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
    >
      <FontAwesomeIcon icon={['fal', deletable ? 'times' : 'check']} />
    </Box>
  );

  return (
    <Chip
      label={file.name}
      color="primary"
      icon={<FileIcon file={file} />}
      onClick={handleDownloadFile}
      onDelete={deleteCallback}
      deleteIcon={deleteIcon}
    />
  );
};

export default FileChip;
