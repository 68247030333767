import { User } from '../types';

export type UserState = {
  isLoggedIn: boolean;
  isImitating: boolean;
  account: User | null;
  requestTwoFactorCode: boolean;
  requestTwoFactorConfiguration: boolean;
};

const defaultState: UserState = {
  isLoggedIn: false,
  isImitating: false,
  account: null,
  requestTwoFactorCode: false,
  requestTwoFactorConfiguration: false,
};

const user = (
  state = defaultState,
  action: { type: string; payload?: unknown },
) => {
  switch (action.type) {
    case 'LOGIN_USER':
      return {
        ...state,
        isLoggedIn: true,
        requestTwoFactorCode: false,
      };
    case 'LOGOUT_USER':
      return {
        ...state,
        isLoggedIn: false,
        account: null,
        requestTwoFactorCode: false,
      };
    case 'SET_ACCOUNT':
      return {
        ...state,
        account: action.payload,
      };
    case 'SET_IMITATING':
      return {
        ...state,
        isImitating: action.payload,
      };
    case 'REQUEST_TWO_FACTOR_CODE':
      return {
        ...state,
        requestTwoFactorCode: true,
      };
    case 'REQUEST_TWO_FACTOR_CONFIGURATION':
      return {
        ...state,
        requestTwoFactorConfiguration: true,
      };
    case 'FULFILL_TWO_FACTOR_CONFIGURATION_REQUEST':
      return {
        ...state,
        requestTwoFactorConfiguration: false,
      };
    default:
      return state;
  }
};

export default user;
