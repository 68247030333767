import React, {
  ChangeEvent,
  FormEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Prompt, useHistory } from 'react-router-dom';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  debounce,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@mui/styles';

import { Autocomplete, DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateFnsUtils from '@date-io/date-fns';

// eslint-disable-next-line import/no-unresolved
import { Flow as IFlow } from 'flowjs';
import { useSnackbar } from 'notistack';
import {
  Category,
  MinimalFund,
  SaveProjectDTO,
  Status,
  File as FileInterface,
  Fund,
  fundToMinimalFund,
} from '../../types';
import AppContext from '../../AppContext';
import CategoryRepository from './CategoryRepository';
import Loader from '../../components/Loader';
import HtmlEditor from '../../components/HtmlEditor';
import FileChip from '../../components/file/FileChip';
import FileUpload from '../../components/file/FileUpload';
import FileIcon from '../../components/file/FileIcon';
import { createFlow, getFileURL } from '../../utils/common';
import {
  hasViolationForField,
  ValidationViolation,
} from '../../utils/validation';
import FundRepository from '../funds/FundRepository';
import ProjectRepository from './ProjectRepository';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  lessonBox: {
    display: 'inline-flex',
  },
}));

type ProjectFormProps = {
  project?: SaveProjectDTO;
  funds?: MinimalFund[];
  attachments?: FileInterface[];
};

const ProjectForm = (props: ProjectFormProps) => {
  const classes = useStyles();
  const notifications = useSnackbar();
  const history = useHistory();

  const { project: existingProject } = props;
  const { roleViewManager } = useContext(AppContext);

  const hasCentralManagerPermissions =
    roleViewManager.isAdminView() || roleViewManager.isCentralManagerView();

  const initialProject =
    existingProject ||
    ({
      title: '',
      description: '',
      shortDescription: '',
      startDate: new Date(),
      endDate: null,
      concept: true,
      status: 'ACTIVE',
      finance: {
        fundCosts: null,
        generalPractitionerCosts: null,
        description: '',
        public: false,
      },
      fundId: hasCentralManagerPermissions
        ? ''
        : roleViewManager.getUser().fund?.id ?? '',
      categories: [],
      attachments: [],
      attachmentIds: [],
      evaluation: '',
      evaluationPublic: false,
      logoId: '',
    } as SaveProjectDTO);

  const [project, setProject] = useState<SaveProjectDTO>(initialProject);

  const [loaded, setLoaded] = useState<boolean>(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [selectedFund, setSelectedFund] = useState<MinimalFund | null>(null);
  const [funds, setFunds] = useState<MinimalFund[]>([]);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [logo, setLogo] = useState<File | null>(null);
  const [attachmentsSaved, setAttachmentsSaved] = useState<boolean>(false);
  const [logoSaved, setLogoSaved] = useState<boolean>(false);
  const [violations, setViolations] = useState<ValidationViolation[]>([]);
  const [formIsWIP, setFormIsWIP] = useState<boolean>(true);
  const [formIsSubmitting, setFormIsSubmitting] = useState<boolean>(false);

  const filesSaved = logoSaved && attachmentsSaved;

  const projectRepository = new ProjectRepository();

  const doStartDateChange = debounce((startDate: Date | null) => {
    setProject({ ...project, startDate });
  }, 800);

  const doEndDateChange = debounce((endDate: Date | null) => {
    setProject({ ...project, endDate });
  }, 800);

  const handleStartDateChange = (startDate: Date | null) =>
    doStartDateChange(startDate);
  const handleEndDateChange = (endDate: Date | null) =>
    doEndDateChange(endDate);
  const handleStatusChange = (
    event: React.MouseEvent<HTMLElement>,
    status: string,
  ) => {
    setProject({ ...project, status: status as Status });
  };
  const handleFundChange = (fund: MinimalFund | null) => {
    setSelectedFund(fund);
    setProject({ ...project, fundId: fund?.id || '' });
  };

  const handleFundCostChange = (event: ChangeEvent<HTMLInputElement>) => {
    const f = project.finance;
    f.fundCosts = parseInt(event.target.value, 10);
    setProject({
      ...project,
      finance: f,
    });
  };

  const handleGeneralPractitionerCostsChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const f = project.finance;
    f.generalPractitionerCosts = parseInt(event.target.value, 10);
    setProject({
      ...project,
      finance: f,
    });
  };

  const handleFinanceDescriptionChange = (event: any, editor: any) => {
    const newFinance = project.finance;
    newFinance.description = editor.getData();

    setProject({
      ...project,
      finance: newFinance,
    });
  };

  const handlePublicChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProject({
      ...project,
      finance: {
        ...project.finance,
        public: event.target.checked,
      },
    });
  };

  const saveLogo = () => {
    if (logo) {
      const flow: IFlow = createFlow();
      flow.addFile(logo);

      let logoId: string;

      flow.on('fileSuccess', (flowFile, message) => {
        const response: FileInterface = JSON.parse(message);
        logoId = response.id;
      });
      flow.on('complete', () => {
        setProject({ ...project, logoId });
        setLogoSaved(true);
      });

      flow.upload();
    } else {
      setLogoSaved(true);
    }
  };

  const saveAttachments = () => {
    const { attachmentIds } = project;
    if (attachments.length > 0) {
      const flow: IFlow = createFlow();
      attachments.forEach((a) => flow.addFile(a));

      flow.on('fileSuccess', (flowFile, message) => {
        const response: FileInterface = JSON.parse(message);
        attachmentIds.push(response.id);
      });
      flow.on('complete', () => {
        setProject((project) => ({ ...project, attachmentIds }));
        setAttachmentsSaved(true);
      });

      flow.upload();
    } else {
      setAttachmentsSaved(true);
    }
  };

  const doSubmit = (project: SaveProjectDTO) => {
    const promise = project?.id
      ? projectRepository.update(project)
      : projectRepository.create(project);

    promise
      .then(() => {
        setFormIsWIP(false);
        history.push('/projecten');
        notifications.enqueueSnackbar(
          `Project is succesvol ${project?.id ? 'gewijzigd' : 'aangemaakt'}!`,
          { variant: 'success' },
        );
      })
      .catch((err) => {
        const { data } = err.response;

        if ('violations' in data) {
          setViolations(data.violations);
        }

        setFormIsSubmitting(false);

        notifications.enqueueSnackbar(
          `Er is iets fout gegaan bij het ${
            project?.id ? 'wijzigen' : 'aanmaken'
          } van het project.`,
          { variant: 'error' },
        );
      });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formIsSubmitting) {
      return;
    }

    setFormIsSubmitting(true);

    if (filesSaved) {
      doSubmit(project);
    } else {
      if (!logoSaved) {
        saveLogo();
      }
      if (!attachmentsSaved) {
        saveAttachments();
      }
    }
  };

  const handleDescriptionChange = (e: any, editor: any) => {
    setProject({ ...project, description: editor.getData() });
  };

  const handleShortDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProject({ ...project, shortDescription: e.target.value as string });
  };

  const handleEvaluationChange = (e: any, editor: any) => {
    setProject({ ...project, evaluation: editor.getData() });
  };

  const handleEvaluationPublicChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setProject({
      ...project,
      evaluationPublic: event.target.checked,
    });
  };

  const handleAttachmentsChange = (files: File[]) => {
    setAttachments(files);
    setAttachmentsSaved(false);
  };

  const deleteAttachment = (attachment: FileInterface) => {
    const updatedAttachments = project.attachments.filter(
      (a) => a.id !== attachment.id,
    );
    setProject((project) => ({
      ...project,
      attachments: updatedAttachments,
      attachmentIds: updatedAttachments.map((a) => a.id),
    }));
  };

  const handleLogoRemoval = () => {
    setProject((oldState: SaveProjectDTO) => {
      return { ...oldState, logo: undefined, logoId: '' };
    });
  };

  const handleLogoChange = (files: File[]) => {
    if (files.length > 0) {
      setLogo(files[0]);
      setLogoSaved(false);
    }
  };

  useEffect(() => {
    setProject({ ...project, categories: selectedCategories.map((c) => c.id) });
  }, [selectedCategories]);

  useEffect(() => {
    new CategoryRepository()
      .findBy({ filters: { perPage: 1000 } })
      .then((response) => {
        setCategories(response.data.items);
        setSelectedCategories(
          response.data.items.filter((c) => project.categories.includes(c.id)),
        );
      })
      .finally(() => setLoaded(true));
  }, []);

  useEffect(() => {
    if (!hasCentralManagerPermissions) {
      return;
    }

    new FundRepository(false).findBy(undefined, 1, 1e6).then((res) => {
      const funds: Array<MinimalFund> = [];
      res.data.items.forEach((fund: Fund) => {
        funds.push(fundToMinimalFund(fund));
      });
      setFunds(funds);
      setSelectedFund(
        res.data.items.find((fund) => fund.id === project.fundId) || null,
      );
    });
  }, []);

  useEffect(
    () => {
      if (filesSaved) {
        doSubmit(project);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filesSaved],
  );

  if (!loaded) {
    return <Loader />;
  }

  const conceptControl = (
    <Checkbox
      checked={project.concept}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        setProject({
          ...project,
          concept: e.target.checked,
        });
      }}
      color="primary"
    />
  );

  const publicFinanceControl = (
    <Checkbox
      checked={project.finance.public}
      onChange={handlePublicChange}
      color="primary"
    />
  );

  const publicEvaluationControl = (
    <Checkbox
      checked={project.evaluationPublic}
      onChange={handleEvaluationPublicChange}
      color="primary"
    />
  );

  return (
    <Paper className={classes.container}>
      <Prompt
        when={formIsWIP}
        message="Weet je zeker dat je de pagina wilt verlaten? Het project is nog niet opgeslagen."
      />
      <LocalizationProvider dateAdapter={AdapterDateFns} utils={DateFnsUtils}>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Box mb={1}>Toelichting: velden met een * zijn verplicht.</Box>
          <Box py={1}>
            <TextField
              label="Titel"
              value={project.title}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setProject({
                  ...project,
                  title: e.target.value,
                });
              }}
              fullWidth
              required
            />
          </Box>
          <Box py={1}>
            {hasViolationForField('shortDescription', violations) && (
              <Box color="red" mb={1}>
                Maximaal 255 tekens
              </Box>
            )}
            <TextField
              label="Samenvatting (korte beschrijving van het project)"
              value={project.shortDescription}
              onChange={handleShortDescriptionChange}
              fullWidth
              multiline
              rows={3}
              error={hasViolationForField('shortDescription', violations)}
            />
          </Box>
          <Box py={1}>
            <FormLabel>Beschrijving</FormLabel>
            {hasViolationForField('description', violations) && (
              <Box color="red">Maximaal 10.000 tekens</Box>
            )}
            <HtmlEditor
              data={project.description}
              onChange={handleDescriptionChange}
              error={hasViolationForField('description', violations)}
            />
          </Box>
          <Box py={1}>
            <DatePicker
              label="Startdatum project *"
              value={project.startDate}
              onChange={handleStartDateChange}
              inputFormat="dd-MM-yyyy"
              orientation="landscape"
              renderInput={(params) => (
                <TextField
                  name="startDate"
                  label="Startdatum"
                  aria-describedby="Verander startdatum"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  error={hasViolationForField('startDate', violations)}
                />
              )}
            />
          </Box>
          <Box py={1}>
            <DatePicker
              label="Einddatum project"
              value={project.endDate}
              onChange={handleEndDateChange}
              inputFormat="dd-MM-yyyy"
              orientation="landscape"
              renderInput={(params) => (
                <TextField
                  name="endDate"
                  label="Einddatum"
                  aria-describedby="Verander einddatum"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                />
              )}
            />
          </Box>
          <Box py={1}>
            <FormLabel>Uitgelichte afbeelding</FormLabel>
            <Box my={0.5}>
              Suggestie: kies een logo of rechtenvrije afbeelding die past bij
              het project. Zorg dat de resolutie van de afbeelding 1280x720
              pixels is, en dat de afbeelding niet groter is dan 1MB. Gebruik
              bij voorkeur een png-bestand.
            </Box>
            {project?.logo ? (
              <Box>
                <FileChip file={project.logo} onDelete={handleLogoRemoval} />
              </Box>
            ) : (
              <FileUpload onChange={handleLogoChange} multiple={false} images />
            )}
          </Box>
          <Box py={1}>
            <FormControlLabel control={conceptControl} label="Concept" />
          </Box>
          <Box py={1}>
            <ToggleButtonGroup
              color="primary"
              exclusive
              value={project.status}
              onChange={handleStatusChange}
              aria-label="project status"
            >
              <ToggleButton value="ACTIVE" aria-label="bold">
                Actief
              </ToggleButton>
              <ToggleButton value="COMPLETED" aria-label="bold">
                Afgerond
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box py={1}>
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Selecteer minimaal één categorie..."
                  variant="outlined"
                  error={hasViolationForField('categories', violations)}
                  fullWidth
                />
              )}
              getOptionLabel={(option) => option.name}
              options={categories}
              autoComplete
              onChange={(event: any, newValue: Category[]) => {
                setSelectedCategories(newValue);
              }}
              value={selectedCategories}
              noOptionsText="Geen categorieën gevonden"
              multiple
            />
          </Box>
          {hasCentralManagerPermissions && (
            <Box py={1}>
              <Autocomplete
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    label="Selecteer een fonds..."
                    variant="outlined"
                    error={hasViolationForField('fundId', violations)}
                    fullWidth
                  />
                )}
                getOptionLabel={(fund) => fund.name}
                options={funds}
                autoComplete
                onChange={(event: any, newValue: MinimalFund | null) => {
                  handleFundChange(newValue);
                }}
                value={selectedFund}
                noOptionsText="Geen fondsen gevonden"
              />
            </Box>
          )}
          <Box py={1}>
            <Accordion>
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={['fad', 'sort-up']} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Financiële informatie</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box py={1}>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      ),
                      inputProps: { min: 0, max: 999999999 },
                    }}
                    type="number"
                    label="Projectkosten"
                    value={project.finance.fundCosts}
                    onChange={handleFundCostChange}
                    fullWidth
                  />
                </Box>
                <Box py={1}>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      ),
                      inputProps: { min: 0, max: 9999999999 },
                    }}
                    type="number"
                    label="Kosten voor de huisarts"
                    value={project.finance.generalPractitionerCosts}
                    onChange={handleGeneralPractitionerCostsChange}
                    fullWidth
                  />
                </Box>
                <Box py={1}>
                  <Box>Toelichting</Box>
                  <HtmlEditor
                    data={project.finance.description}
                    onChange={handleFinanceDescriptionChange}
                  />
                </Box>
                <Box py={1}>
                  <FormControlLabel
                    control={publicFinanceControl}
                    label="Openbaar (ik geef toestemming om de financiële informatie zichtbaar te maken op achterstandsfondsen.nl)"
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box py={1}>
            <FormLabel>Evaluatie</FormLabel>
            {hasViolationForField('evaluation', violations) && (
              <Box color="red">Maximaal 10.000 tekens</Box>
            )}
            <HtmlEditor
              data={project.evaluation || ''}
              onChange={handleEvaluationChange}
              error={hasViolationForField('evaluation', violations)}
            />
            <FormControlLabel
              control={publicEvaluationControl}
              label="Openbaar (ik geef toestemming om de evaluatie zichtbaar te maken op achterstandsfondsen.nl)"
            />
          </Box>
          <Box mt={2}>
            <FormLabel>Bijlagen</FormLabel>
            {project.attachments.length > 0 && (
              <List>
                {project.attachments.map((attachment) => (
                  <ListItem key={attachment.id}>
                    <ListItemIcon>
                      <FileIcon file={attachment} />
                    </ListItemIcon>
                    <ListItemText>
                      <a
                        href={getFileURL(attachment)}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {attachment.name}
                      </a>
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => deleteAttachment(attachment)}
                      >
                        <FontAwesomeIcon icon={['fal', 'times']} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )}
            <FileUpload
              onChange={handleAttachmentsChange}
              images
              audio
              documents
              video
              multiple
            />
          </Box>
          <Box py={1}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={formIsSubmitting}
              startIcon={<FontAwesomeIcon icon={['fad', 'save']} />}
            >
              Opslaan
            </Button>
          </Box>
        </form>
      </LocalizationProvider>
    </Paper>
  );
};

export default ProjectForm;
