import { AxiosResponse } from 'axios';
import {
  ApiFilterCriteria,
  Category,
  CategoryDTO,
  CollectionResponse,
  Repository,
} from '../../types';
import { parseCriteria } from '../../utils/common';
import ApiClient from '../../api/ApiClient';

class CategoryRepository implements Repository<Category> {
  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
  ): Promise<CollectionResponse<Category>> {
    const params = parseCriteria(criteria, { page: page || 1 });

    return ApiClient.get('/api/categories', params);
  }

  find(id: string): Promise<AxiosResponse<Category>> {
    return ApiClient.get(`/api/categories/${id}`);
  }

  create(category: CategoryDTO): Promise<AxiosResponse<Category>> {
    return ApiClient.post('/api/categories', category);
  }

  update(id: string, category: CategoryDTO): Promise<AxiosResponse<Category>> {
    return ApiClient.put(`/api/categories/${id}`, category);
  }

  delete?(id: string): Promise<AxiosResponse> {
    return ApiClient.delete(`/api/categories/${id}`);
  }
}

export default CategoryRepository;
