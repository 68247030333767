import { User } from '../../types';

const NullUser: User = {
  active: false,
  email: '',
  firstName: '',
  insertion: '',
  lastName: '',
  roles: ['ROLE_FUND_USER'],
  fund: null,
};

export default NullUser;
