import React, { useMemo, useState } from 'react';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import DataTable from '../../components/DataTable';
import { Category } from '../../types';
import PageContainer from '../../components/PageContainer';
import CategoryRepository from './CategoryRepository';
import SearchContext from '../../components/search/SearchContext';
import SearchInput from '../../components/search/SearchInput';
import { CATEGORY_OVERVIEW } from './index';

const CategoryOverview = () => {
  const [query, setQuery] = useState<string>('');
  const repository = useMemo(() => new CategoryRepository(), []);

  const searchContextValue = useMemo(
    () => ({ query, setQuery }),
    [query, setQuery],
  );

  const columns = [
    {
      name: 'Naam',
      field: 'name',
      sortable: false,
    },
    {
      name: 'Beschrijving',
      field: 'description',
      sortable: false,
    },
    {
      name: 'Aantal projecten',
      field: 'projectCount',
      sortable: false,
    },
    {
      name: 'Aangemaakt op',
      field: 'created',
      render: (category: Category) =>
        format(new Date(category.created), 'dd/MM/yyyy'),
    },
  ];

  const itemActions = (category: Category, className: string) => (
    <div>
      <Tooltip title="Categorie bewerken">
        <IconButton
          size="small"
          component={Link}
          to={`${CATEGORY_OVERVIEW}/${category.id}`}
          className={className}
        >
          <FontAwesomeIcon icon={['fal', 'edit']} />
        </IconButton>
      </Tooltip>
    </div>
  );

  const actions = (
    <Box display="flex" alignItems="center">
      <Box mr={3}>
        <SearchInput />
      </Box>
      <Button
        component={Link}
        to={`${CATEGORY_OVERVIEW}/aanmaken`}
        variant="contained"
        color="primary"
        startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
      >
        Nieuwe categorie
      </Button>
    </Box>
  );

  return (
    <SearchContext.Provider value={searchContextValue}>
      <PageContainer title="Categorieën" actions={actions}>
        <Box mt={3}>
          <DataTable
            id="category-overview"
            repository={repository}
            columns={columns}
            actions={itemActions}
            searchable
            deletable
            deleteItemMessage={(category: Category) => {
              return `Weet je zeker dat je "${category.name}" wilt verwijderen?`;
            }}
          />
        </Box>
      </PageContainer>
    </SearchContext.Provider>
  );
};

export default CategoryOverview;
