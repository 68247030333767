export interface RoleInterface {
  ROLE_ADMIN: string;
  ROLE_CENTRAL_MANAGER: string;
  ROLE_FUND_MANAGER: string;
  ROLE_FUND_USER: string;
}

const Roles: RoleInterface = {
  ROLE_ADMIN: 'Admin',
  ROLE_CENTRAL_MANAGER: 'Centraal manager',
  ROLE_FUND_MANAGER: 'Fondsmanager',
  ROLE_FUND_USER: 'Fondsmedewerker',
};

export default Roles;
