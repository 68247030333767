import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { SnackbarProvider } from 'notistack';
import 'url-search-params-polyfill';

import './index.css';
import App from './App';
import rootReducer from './reducers';
import * as serviceWorker from './serviceWorker';
import {
  loginUser,
  requestTwoFactorCode,
  requestTwoFactorConfiguration,
  setAccount,
} from './actions';
import UserRepository from './modules/users/UserRepository';

const store = createStore(rootReducer, applyMiddleware(thunk));

// Attempt to get account from API.
new UserRepository()
  .getAccount()
  .then((response) => {
    // If succesful, login the user.
    store.dispatch(loginUser());
    store.dispatch(setAccount(response.data));
  })
  .catch((err) => {
    if (err.response.data['2fa_complete'] === false) {
      store.dispatch(requestTwoFactorCode());
    }

    if (err.response.data.error === '2fa_configuration_required') {
      store.dispatch(setAccount(err.response.data.account));
      store.dispatch(requestTwoFactorConfiguration());
    }
  })
  .finally(() => {
    // Render the DOM once login check is finished.
    ReactDOM.render(
      <Provider store={store}>
        <SnackbarProvider
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          maxSnack={3}
          autoHideDuration={3500}
        >
          <App />
        </SnackbarProvider>
      </Provider>,
      document.getElementById('root'),
    );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
