import { adaptV4Theme, createTheme, Theme } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';
import { grey } from '@mui/material/colors';

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

export const colors = {
  white: '#FFFFFF',
  lightestGrey: '#F3F3F3',
  darkGrey: '#19333C',
  darkerGrey: '#17262b',
  shamrock: '#41d4a2',
  aquaHaze: '#F2F5F8',
  toreaBay: '#0e2291',
  royalBlue: '#2f4beb',
  blueZodiac: '#0f164b',
};

export const gradients = {
  primaryLight:
    'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(236,241,245,1) 100%)',
  primaryUltraLight:
    'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(245,248,250,1) 100%)',
};

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        light: '#EBEFF5',
        main: '#435DF4',
        dark: '#0E2291',
        // dark: will be calculated from palette.primary.main,
        contrastText: '#fff',
      },
    },

    shadows: [
      'none',
      '1px 2px 7px -1px rgba(0,0,0,0.05), 0px 1px 1px 0px rgba(0,0,0,0.09), 0px 2px 4px 0px rgba(0,0,0,0.10)',
      '1px 2px 7px -1px rgba(0,0,0,0.05), 0px 1px 1px 0px rgba(0,0,0,0.09), 0px 2px 4px 0px rgba(0,0,0,0.10)',
      '1px 2px 8px 2px rgba(0,0,0,0.05)',
      ...Array(21).fill('none'),
    ] as Shadows,

    typography: {
      fontFamily: ['Lexend Deca', 'sans-serif'].join(','),
      fontWeightMedium: 300,
      fontWeightBold: 700,
      h1: {
        fontFamily: 'Lexend Deca, sans-serif',
        fontSize: 28,
        fontWeight: 700,
        color: colors.blueZodiac,
      },
      h2: {
        fontFamily: 'Lexend Deca, sans-serif',
        fontWeight: 700,
        color: colors.blueZodiac,
      },
      h3: {
        fontFamily: 'Lexend Deca, sans-serif',
        fontWeight: 700,
        color: colors.blueZodiac,
      },
      h4: {
        fontFamily: 'Lexend Deca, sans-serif',
        fontWeight: 700,
        color: colors.blueZodiac,
      },
      h5: {
        fontFamily: 'Lexend Deca, sans-serif',
        fontWeight: 700,
        color: colors.blueZodiac,
      },
      h6: {
        fontFamily: 'Lexend Deca, sans-serif',
        fontWeight: 700,
        color: colors.blueZodiac,
      },
    },

    overrides: {
      MuiButton: {
        root: {
          textTransform: 'none',
        },
      },
      MuiChip: {
        root: {
          borderRadius: 0,
        },
      },
      MuiTabs: {
        indicator: {
          display: 'none',
        },
      },
      MuiTab: {
        root: {
          marginRight: 8,
          background: grey[100],
          transition: 'all 0.3s ease',
          '&[aria-selected="true"]': {
            background: '#FFF',
            boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.09)',
          },
          textTransform: 'none',
          fontWeight: 600,
        },
      },
    },
  }),
);

export default theme;
