import { AxiosResponse } from 'axios';

import ApiClient from '../../api/ApiClient';
import {
  ApiFilterCriteria,
  CollectionResponse,
  Fund,
  FundDto,
  Repository,
} from '../../types';
import { parseCriteria } from '../../utils/common';

class FundRepository implements Repository<Fund> {
  private readonly showDeleted: boolean;

  constructor(showDeleted: boolean = true) {
    this.showDeleted = showDeleted;
  }

  find(id: string): Promise<AxiosResponse<Fund>> {
    return ApiClient.get(`/api/funds/${id}`);
  }

  update(fund: FundDto) {
    return ApiClient.put(`/api/funds/${fund.id}`, fund);
  }

  create(fund: FundDto) {
    return ApiClient.post('/api/funds', fund);
  }

  delete(id: string) {
    return ApiClient.delete(`/api/funds/${id}`);
  }

  findBy(
    criteria?: ApiFilterCriteria,
    page: number = 1,
    perPage: number = 10,
  ): Promise<CollectionResponse<Fund>> {
    const params = parseCriteria(criteria, {
      page,
      perPage,
      showSoftDeleted: this.showDeleted ? 1 : 0,
    });

    return ApiClient.get('/api/funds', params);
  }
}

export default FundRepository;
