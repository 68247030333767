/* eslint-disable linebreak-style */
import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Tooltip,
} from '@mui/material';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination } from '@mui/lab';
import { makeStyles } from '@mui/styles';

import { ApiFilterCriteria, Repository } from '../types';
import { colors } from '../config/theme';
import FilterColumn, {
  FilterColumnConfig,
  FilterColumnOption,
  FilterColumnOptionCallback,
  FilterColumnOptionSearchCallback,
} from './FilterColumn';
import ConditionalWrapper from './ConditionalWrapper';
import SearchContext from './search/SearchContext';
import AppContext from '../AppContext';

interface Item {
  id: string;
  [key: string]: any;
}

export interface Column {
  // Name of the column as shown in the header.
  name: string;
  // Name of the field to show.
  field: string;
  // Determines if the user can sort on this column.
  sortable?: boolean;
  // Filters associated with the column.
  filter?:
    | {
        type: 'checkbox' | 'autocomplete' | 'datepicker';
        options?: FilterColumnOption[] | FilterColumnOptionCallback;
        searchOptions?: FilterColumnOptionSearchCallback;
        config?: FilterColumnConfig;
      }
    | undefined;
  // Custom rendering function for this column.
  render?: (item: any) => ReactNode;
}

interface DataTableProps {
  // A unique identifier for this data table.
  id: string;
  // The repository to use for retrieving the data.
  repository: Repository<unknown>;
  // All the columns of the data table.
  columns: Column[];
  // Whether the items in the data table are searchable.
  searchable?: boolean;
  // Whether items in the data table can be deleted or not.
  deletable?: boolean;
  // Callback when deleting an item fails.
  onDeleteFail?: (item: any) => void;
  // Callback that renders actions that are available for each item.
  actions?: (item: any, className: string, loadItems: () => void) => ReactNode;
  // Callback when the data table loads data.
  onLoad?: (items: any, totalCount: number) => void;
  // Message when the user attempts to delete an item.
  deleteItemMessage?: (item: any) => string;
  // Custom class for the data table.
  className?: string;
  // Whether the data table is contained in a container or not.
  contained?: boolean;
  // Whether the filter state should be persisted.
  persistFilters?: boolean;
  // The filtering that's set on load.
  defaultFilters?: ApiFilterCriteria;
  // Custom display for when there are no results found.
  noResults?: ReactNode;
  // Callback when filters change.
  onFiltersChange?: (filters: ApiFilterCriteria) => void;
  // Callback that renders a table row.
  renderRow?: (
    columns: React.ReactElement,
    item: any,
    rowClassName: string,
    columnClassName: string,
  ) => React.ReactElement;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: colors.white,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  iconButton: {
    marginRight: theme.spacing(1),
  },
  tableWrapper: {
    position: 'relative',
    minHeight: 100,
    width: '100%',
  },
  table: {
    borderCollapse: 'initial',
    borderSpacing: `0 ${theme.spacing(1)}`,
    paddingTop: 0,
  },
  tableContained: {
    paddingTop: 0,
    padding: theme.spacing(2),
  },
  tableContainerNotContained: {
    width: `calc(100% + ${theme.spacing(2)})`,
    padding: theme.spacing(2),
    marginLeft: -1.5 * parseInt(theme.spacing(1), 10),
  },
  tableBody: {
    position: 'relative',
  },
  tr: {
    boxShadow: 'none',
  },
  th: {
    padding: theme.spacing(1),
    borderBottom: 'none',
    fontWeight: theme.typography.fontWeightBold,
    '&:last-of-type': {
      paddingRight: 40,
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  td: {
    position: 'relative',
    padding: theme.spacing(1),
    background: colors.aquaHaze,
    borderBottom: 'none',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  filtersContent: {
    padding: theme.spacing(2),
  },
  buttonMargin: {
    marginRight: theme.spacing(2),
  },
  loader: {
    position: 'absolute',
    top: theme.spacing(2),
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: `calc(100% - ${theme.spacing(2)})`,
    opacity: 1,
  },
  loaderHidden: {
    opacity: 0,
    zIndex: -1,
  },
  noResults: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingBottom: theme.spacing(3),
  },
  clearFiltersButton: {
    position: 'absolute',
    top: 15,
    right: 20,
  },
  resetFiltersIcon: {
    position: 'absolute',
    right: -6,
    bottom: -2,
    fontSize: 12,
  },
}));

const DataTable = (props: DataTableProps) => {
  const classes = useStyles();
  const {
    id,
    repository,
    columns,
    searchable,
    deletable,
    onDeleteFail,
    actions,
    deleteItemMessage,
    className,
    contained,
    persistFilters,
    defaultFilters,
    noResults,
    onFiltersChange,
    onLoad,
    renderRow,
  } = props;
  const { query, setQuery } = useContext(SearchContext);
  const [items, setItems] = useState<Array<unknown>>([]);
  const [ready, setReady] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { appState, setAppState, localStore } = useContext(AppContext);

  const withSearch = (!setQuery && searchable) || false;
  const withDelete = deletable || false;
  const isContained = contained === undefined ? true : contained;

  const [paginator, setPaginator] = useState<{
    totalPages: number | null;
    currentPage: number;
  }>({
    totalPages: null,
    currentPage: 1,
  });

  const { currentPage } = paginator;

  const [filters, setFilters] = useState<ApiFilterCriteria>(
    defaultFilters || {
      query: '',
      filters: {},
      order: undefined,
    },
  );

  const [deleteState, setDeleteState] = useState<{
    showDialog: boolean;
    item: Item | null;
  }>({
    showDialog: false,
    item: null,
  });

  const resetPaginator = () =>
    setPaginator({ totalPages: null, currentPage: 1 });

  const handleClearFilters = () => {
    setFilters({
      query: '',
      filters: {},
      order: undefined,
    });
    localStore.removeItem(`datatable-${id}-filters`);
  };

  /**
   * Search
   */
  const doSearch = useCallback(
    _.debounce((query: string) => {
      if (query === filters.query) {
        return;
      }

      resetPaginator();
      setFilters({ ...filters, query });
    }, 500),
    [filters],
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const q = e.target.value;
    doSearch(q);
  };

  /**
   * Data Loading
   */
  const loadItems = useCallback(async () => {
    setLoading(true);
    try {
      const response = await repository.findBy(filters, currentPage);

      let totalItems;
      let items;
      let perPage = 10;

      if ('data' in response) {
        const { data } = response;

        if ('hydra:member' in data && 'hydra:totalItems' in data) {
          items = data['hydra:member'];
          totalItems = data['hydra:totalItems'];
        } else {
          items = data.items;
          totalItems = data.totalItems;

          if (data.perPage) {
            perPage = data.perPage;
          }
        }
      } else {
        items = response.items;
        totalItems = response.totalItems;

        if (response.perPage) {
          perPage = response.perPage;
        }
      }

      if (onLoad) {
        onLoad(items, totalItems);
      }

      setItems(items);
      setPaginator({
        ...paginator,
        totalPages: Math.ceil(totalItems / perPage),
      });
    } catch (e) {
      handleClearFilters();
      if (e.response && setAppState && appState) {
        setAppState({ ...appState, errorStatusCode: e.response.status });
      }
    }

    setLoading(false);
  }, [filters, currentPage, repository, setItems, setPaginator, setLoading]);

  useEffect(() => {
    if (ready) {
      loadItems();
    }
  }, [filters, currentPage, repository, ready]);

  useEffect(() => {
    doSearch(query);
  }, [query]);

  /**
   * Pagination
   */
  const handlePaginate = (e: ChangeEvent<unknown>, page: number) => {
    setPaginator({ ...paginator, currentPage: page });
  };

  /**
   * Deletion
   */
  const handleDelete = (item: any) => {
    setDeleteState({ item, showDialog: true });
  };

  const handleClose = () => {
    setDeleteState({ item: null, showDialog: false });
  };

  const doDelete = () => {
    if (typeof repository.delete !== 'function') {
      return;
    }

    if (deleteState.item !== null) {
      repository
        .delete(deleteState.item.id)
        .then(() => {
          loadItems();
        })
        .catch(() => {
          if (onDeleteFail) {
            onDeleteFail(deleteState.item);
          }
        });
    }

    setDeleteState({ item: null, showDialog: false });
  };

  /**
   * Sorting
   */
  const handleSort = (property: string) => () => {
    let order: 'asc' | 'desc' = 'asc';

    if (filters.order && filters.order.length > 0) {
      order = filters.order[0].order === 'asc' ? 'desc' : 'asc';
    }

    resetPaginator();
    setFilters({ ...filters, order: [{ field: property, order }] });
  };

  /**
   * Filtering
   */
  const handleFilterColumn = (
    column: Column,
    options: FilterColumnOption[],
  ) => {
    if (filters.filters === undefined) {
      filters.filters = {};
    }

    if (options.length === 0) {
      delete filters.filters[column.field];
    } else {
      filters.filters[column.field] = options;
    }

    resetPaginator();
    setFilters({ ...filters });
  };

  const handleFilterColumnByDate = (column: Column, date: Date | null) => {
    if (!date) {
      if (filters.filters && filters.filters[column.field]) {
        delete filters.filters[column.field];
        setFilters({ ...filters });
      }

      return;
    }

    if (filters.filters === undefined) {
      filters.filters = {};
    }

    resetPaginator();
    filters.filters[column.field] = format(date, 'YYYY-MM-DD');
    setFilters({ ...filters });
  };

  useEffect(() => {
    if (onFiltersChange) {
      onFiltersChange(filters);
    }
  }, [filters]);

  /**
   * State persistence.
   */
  useEffect(() => {
    if (!persistFilters || !ready) {
      return;
    }

    localStore.setItem(`datatable-${id}-filters`, JSON.stringify(filters));
  }, [filters]);

  useEffect(() => {
    if (!persistFilters) {
      setReady(true);
      return;
    }

    localStore
      .getItem<string>(`datatable-${id}-filters`)
      .then((storedFiltersStr) => {
        if (storedFiltersStr === null) {
          return;
        }

        const storedFilters = JSON.parse(storedFiltersStr);
        setFilters({ ...filters, ...storedFilters, query: '' });
      })
      .finally(() => setReady(true));
  }, []);

  const adornment = (
    <InputAdornment position="start">
      <FontAwesomeIcon icon={['fal', 'search']} />
    </InputAdornment>
  );

  return (
    <Box className={className}>
      <Dialog
        open={deleteState.showDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {deleteItemMessage && deleteState.item !== null
              ? deleteItemMessage(deleteState.item)
              : 'Weet je zeker dat je dit item wilt verwijderen?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Annuleren
          </Button>
          <Button onClick={doDelete} color="secondary">
            Verwijderen
          </Button>
        </DialogActions>
      </Dialog>

      <ConditionalWrapper
        condition={isContained}
        wrapper={(children) => (
          <Paper className={classes.root}>{children}</Paper>
        )}
      >
        <>
          <div className={classes.tableWrapper}>
            <TableContainer
              className={isContained ? '' : classes.tableContainerNotContained}
            >
              {withSearch && (
                <Box p={2} pb={0} className={classes.searchContainer}>
                  <FormControl>
                    <Input
                      type="text"
                      onChange={handleSearch}
                      data-testid="search-box"
                      startAdornment={adornment}
                    />
                  </FormControl>
                </Box>
              )}
              <Table
                className={`${classes.table} ${
                  isContained ? classes.tableContained : ''
                }`}
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={`cell-header-${column.field}`}
                        className={`${id}-column ${classes.th}`}
                      >
                        <Box display="flex">
                          {column.sortable && (
                            <TableSortLabel
                              active={
                                filters.order &&
                                filters.order[0].field === column.field
                              }
                              direction={
                                filters.order ? filters.order[0].order : 'asc'
                              }
                              onClick={handleSort(column.field)}
                              data-testid={`column-sort-label-${column.field}`}
                            >
                              {column.name}
                            </TableSortLabel>
                          )}
                          {column.filter !== undefined && (
                            <FilterColumn
                              field={column.field}
                              name={column.name}
                              type={column.filter.type}
                              options={column.filter.options}
                              searchOptions={column.filter.searchOptions}
                              config={column.filter.config || {}}
                              onChange={(options: FilterColumnOption[]) => {
                                handleFilterColumn(column, options);
                              }}
                              onDateChange={(date: Date | null) => {
                                return handleFilterColumnByDate(column, date);
                              }}
                              criteria={filters}
                              showLabel={!column.sortable}
                            />
                          )}
                          {!column.sortable &&
                            column.filter === undefined &&
                            column.name}
                        </Box>
                      </TableCell>
                    ))}
                    {actions && (
                      <TableCell className={`${id}-column ${classes.th}`} />
                    )}
                    {Object.entries(filters.filters || {}).length > 0 && (
                      <Tooltip title="Filters verwijderen">
                        <IconButton
                          size="small"
                          className={classes.clearFiltersButton}
                          onClick={handleClearFilters}
                        >
                          <Box position="relative">
                            <FontAwesomeIcon icon={['fal', 'filter']} />
                            <FontAwesomeIcon
                              icon="trash"
                              size="sm"
                              className={classes.resetFiltersIcon}
                            />
                          </Box>
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {items.map((item) => {
                    const renderedColumns = (
                      <>
                        {columns.map((column) => {
                          if (!column.render) {
                            return (
                              <TableCell
                                key={`${(item as { id: string }).id}-cell-${
                                  column.field
                                }`}
                                className={classes.td}
                              >
                                {(item as any)[column.field]}
                              </TableCell>
                            );
                          }

                          return (
                            <TableCell
                              key={`${(item as { id: string }).id}-cell-${
                                column.field
                              }`}
                              className={classes.td}
                            >
                              {column.render(item)}
                            </TableCell>
                          );
                        })}
                        {(actions || withDelete) && (
                          <TableCell
                            key={`${(item as { id: string }).id}-cell-actions`}
                            className={classes.td}
                          >
                            <div className={classes.actions}>
                              {actions &&
                                actions(item, classes.iconButton, loadItems)}
                              {withDelete && (
                                <Tooltip title="Verwijderen">
                                  <IconButton
                                    size="small"
                                    onClick={() => handleDelete(item as any)}
                                  >
                                    <FontAwesomeIcon icon={['fal', 'trash']} />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </div>
                          </TableCell>
                        )}
                      </>
                    );

                    if (renderRow) {
                      return renderRow(
                        renderedColumns,
                        item,
                        classes.tr,
                        classes.td,
                      );
                    }

                    return (
                      <TableRow
                        key={(item as { id: string }).id}
                        data-testid="table-row"
                        className={classes.tr}
                      >
                        {renderedColumns}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {!loading && items.length === 0 && (
              <div className={classes.noResults}>
                {noResults === undefined && 'Geen resultaten gevonden.'}
                {noResults !== undefined && noResults}
              </div>
            )}
            <div
              className={`${classes.loader} ${
                loading ? '' : classes.loaderHidden
              }`}
            >
              <CircularProgress />
            </div>
          </div>
          {paginator.totalPages !== null && paginator.totalPages > 1 && (
            <Box p={2}>
              <Pagination
                sx={{
                  button: {
                    background: colors.lightestGrey,
                    color: colors.toreaBay,
                    borderRadius: '0%',
                  },
                }}
                color="primary"
                count={paginator.totalPages}
                page={paginator.currentPage}
                onChange={handlePaginate}
              />
            </Box>
          )}
        </>
      </ConditionalWrapper>
    </Box>
  );
};

export default DataTable;
