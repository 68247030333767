import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import PageContainer from '../../components/PageContainer';
import FundRepository from './FundRepository';
import { Fund } from '../../types';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  label: {
    fontWeight: 700,
    width: '30%',
  },
  content: {
    width: '70%',
  },
  tableTitleBox: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  tableTitle: {
    fontSize: theme.typography.h5.fontSize,
  },
}));

const ViewFund = () => {
  const classes = useStyles();
  const repository = new FundRepository();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [fund, setFund] = useState<Fund | null>(null);

  useEffect(() => {
    repository
      .find(id)
      .then((res) => setFund(res.data))
      .catch(() => history.push('/fondsen'));
  }, []);

  return (
    <div>
      {fund && (
        <PageContainer title="Fondsinformatie">
          <TableContainer component={Paper}>
            <Box className={classes.tableTitleBox}>
              <h2 className={classes.tableTitle}>Algemene informatie</h2>
            </Box>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.label}>Naam</TableCell>
                  <TableCell className={classes.content}>
                    {fund.name ? fund.name : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.label}>Website</TableCell>
                  <TableCell className={classes.content}>
                    {fund.websiteUrl ? fund.websiteUrl : '-'}
                  </TableCell>
                </TableRow>
                {fund.places && (
                  <TableRow>
                    <TableCell className={classes.label}>Plaatsen</TableCell>
                    <TableCell className={classes.content}>
                      {fund.places.map((p) => (
                        <Box>{p.name}</Box>
                      ))}
                    </TableCell>
                  </TableRow>
                )}
                {fund.municipalities && (
                  <TableRow>
                    <TableCell className={classes.label}>Gemeentes</TableCell>
                    <TableCell className={classes.content}>
                      {fund.municipalities.map((m) => (
                        <Box>{`${m.name} (${m.code})`}</Box>
                      ))}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </PageContainer>
      )}
    </div>
  );
};

export default ViewFund;
