import React from 'react';
import { Route, Router } from '../../routing';
import FundOverview from './FundOverview';
import CreateFund from './CreateFund';
import ViewFund from './ViewFund';
import EditFund from './EditFund';

const funds = new Route('Fondsen', '/fondsen', <FundOverview />);
funds.addChild(new Route('Fonds aanmaken', '/aanmaken', <CreateFund />));
funds.addChild(new Route('Fonds bekijken', '/:id', <ViewFund />));
funds.addChild(new Route('Fonds bewerken', '/:id/bewerken', <EditFund />));
Router.addRoute(funds);
