import { useContext } from 'react';
import { useSelector } from 'react-redux';

import AppContext from '../../AppContext';
import { UserState } from '../../reducers/user';
import { CRUDPermission, User } from '../../types';
import RoleViewManager from '../../RoleViewManager';

interface FundCRUDPermission extends CRUDPermission {
  canEditManager: boolean;
}

// extract logic for whether to allow fund editing,
// so it can be used in the fund overview at the table row level
export const canEditFund = (
  fundId: string,
  roleViewManager: RoleViewManager,
  account: User | null,
) => {
  return (
    roleViewManager.isAdminView() ||
    roleViewManager.isCentralManagerView() ||
    (roleViewManager.isFundManagerView() && fundId === account?.fund?.id)
  );
};

const useFundPermissions = (fundId?: string): FundCRUDPermission => {
  const { roleViewManager } = useContext(AppContext);
  const { account } = useSelector((selector: { user: UserState }) => ({
    account: selector.user.account,
  }));

  const hasCentralManagerPermissions =
    roleViewManager.isAdminView() || roleViewManager.isCentralManagerView();

  const canCreate = hasCentralManagerPermissions;
  const canEdit =
    fundId !== undefined
      ? canEditFund(fundId, roleViewManager, account)
      : hasCentralManagerPermissions;
  const canEditManager = hasCentralManagerPermissions;
  const canDelete = hasCentralManagerPermissions;

  return {
    canCreate,
    canEdit,
    canEditManager,
    canView: true,
    canDelete,
  };
};

export default useFundPermissions;
