import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import React from 'react';
import { ReactComponent as CircleSvg } from '../assets/images/circle.svg';

const useStyles = makeStyles((theme: Theme) => ({
  circle: {
    position: 'absolute',
    right: '-600px',
    top: 'calc(50% - 600px)',
    [theme.breakpoints.down('md')]: {
      right: '-800px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const Circle = () => {
  const classes = useStyles();

  return <CircleSvg className={classes.circle} />;
};

export default Circle;
