import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';

import ApiClient from '../api/ApiClient';
import { User } from '../types';

export const loginUser = () => ({
  type: 'LOGIN_USER',
});

export const logoutUser = () => ({
  type: 'LOGOUT_USER',
});

export const setAccount = (user: User) => ({
  type: 'SET_ACCOUNT',
  payload: user,
});

export const requestTwoFactorCode = () => ({
  type: 'REQUEST_TWO_FACTOR_CODE',
});

export const resetTwoFactorCodeRequest = () => ({
  type: 'RESET_TWO_FACTOR_CODE_REQUEST',
});

export const requestTwoFactorConfiguration = () => ({
  type: 'REQUEST_TWO_FACTOR_CONFIGURATION',
});

export const fulfillTwoFactorConfigurationRequest = () => ({
  type: 'FULFILL_TWO_FACTOR_CONFIGURATION_REQUEST',
});

export const refreshAccount =
  (callback?: () => void): ThunkAction<void, any, any, any> =>
  async (dispatch) => {
    const account: AxiosResponse<User> = await ApiClient.get(
      '/api/users/account',
    );
    dispatch(setAccount(account.data));

    if (callback) {
      setTimeout(() => {
        callback();
      }, 0);
    }
  };

export const setImitating = (status: boolean) => ({
  type: 'SET_IMITATING',
  payload: status,
});
