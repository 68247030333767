import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material/styles';
import { useSnackbar } from 'notistack';

import './App.scss';
import './config/icons';
import localforage from 'localforage';
import { AxiosResponse } from 'axios';
import { Box } from '@mui/material';
import theme, { colors } from './config/theme';

import Navigation from './Navigation';
import { Route, Router } from './routing';
import Error404 from './components/error/Error404';

// Modules
import './modules/funds';
import './modules/projects';
import './modules/users';

import Login from './Login';
import UserRepository from './modules/users/UserRepository';
import { setAccount } from './actions';
import { UserState } from './reducers/user';
import Loader from './components/Loader';
import AppContext from './AppContext';
import Footer from './Footer';
import RoleViewManager from './RoleViewManager';
import NullUser from './modules/users/NullUser';
import UserImitator from './modules/users/UserImitator';
import ErrorHandler from './ErrorHandler';
import TwoFactorWall from './modules/users/TwoFactorWall';
import { User } from './types';
import UsersOverview from './modules/users/UsersOverview';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Router
Router.setNotFoundComponent(<Error404 />);
Router.addRoute(new Route('Login', '/', <Login />).anonymous());
Router.addRoute(
  new Route('Gebruikers', '/', <UsersOverview />).role([
    'ROLE_ADMIN',
    'ROLE_CENTRAL_MANAGER',
    'ROLE_CENTRAL_MANAGER',
    'ROLE_FUND_MANAGER',
  ]),
);

export interface AppState {
  user: User | null;
  loaded: boolean;
  errorStatusCode: number | null;
  errorDetails: React.ReactElement | null;
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    // Always show sidebar to prevent jumps
    minHeight: 'calc(100vh + 1px)',
    background: colors.lightestGrey,
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: '1 0 auto',
  },
}));

function App() {
  const classes = useStyles();
  const { isLoggedIn, isImitating, account, requestTwoFactorConfiguration } =
    useSelector((selector: { user: UserState }) => ({
      isLoggedIn: selector.user.isLoggedIn,
      isImitating: selector.user.account && selector.user.account.isImitating,
      account: selector.user.account,
      requestTwoFactorConfiguration:
        selector.user.requestTwoFactorConfiguration,
    }));
  const notifications = useSnackbar();
  const dispatch = useDispatch();

  const [state, setState] = useState<AppState>({
    user: null,
    loaded: false,
    errorStatusCode: null,
    errorDetails: null,
  });

  const localStore = useMemo(
    () => localforage.createInstance({ name: 'achterstandsfondsen' }),
    [],
  );
  const roleViewManager = useMemo(
    () => new RoleViewManager(account || NullUser, undefined, localStore),
    [account],
  );

  /**
   * Attempt to get account data from API.
   */
  const fetchAccount = async () => {
    const repository = new UserRepository();

    if (!account) {
      let accountResponse: AxiosResponse<User>;

      try {
        accountResponse = await repository.getAccount();
      } catch (e) {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het inloggen!',
          { variant: 'error' },
        );
        return;
      }

      dispatch(setAccount(accountResponse.data));
      setState({
        ...state,
        user: accountResponse.data,
      });
    }

    const imitator = new UserImitator(
      dispatch,
      roleViewManager,
      localStore,
      account?.isImitating || false,
    );
    await imitator.isImitating();
  };

  /**
   * Trigger when isLoggedIn or isImitating changes.
   */
  useEffect(() => {
    if (!isLoggedIn) {
      setState({
        ...state,
        user: null,
        loaded: true,
      });
      return;
    }

    fetchAccount();
  }, [isLoggedIn, isImitating]);

  useEffect(() => {
    // Load prefered view from local store.
    roleViewManager.loadViewFromLocalStore(() => {
      setState({ ...state, loaded: true });
    });
  }, [account, isLoggedIn]);

  const routes = useMemo(() => {
    if (account && requestTwoFactorConfiguration) {
      return <TwoFactorWall account={account} />;
    }

    return (
      <BrowserRouter>
        <Navigation />
        <ErrorHandler />
        {state.errorStatusCode === null && Router.getSwitch(account)}
      </BrowserRouter>
    );
  }, [account, state, requestTwoFactorConfiguration]);

  const appContextValue = useMemo(
    () => ({
      appState: state,
      setAppState: setState,
      roleViewManager,
      localStore,
    }),
    [state, roleViewManager, localStore],
  );

  if (!state.loaded) {
    return <Loader />;
  }

  return (
    <div className={classes.root}>
      <AppContext.Provider value={appContextValue}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <div className={classes.wrapper}>
              <div className={classes.content}>
                <Box width="100%">{routes}</Box>
              </div>
              <Footer />
            </div>
          </ThemeProvider>
        </StyledEngineProvider>
      </AppContext.Provider>
    </div>
  );
}

export default App;
