import { AxiosResponse } from 'axios';

export interface ValidationViolation {
  message: string;
  propertyPath: string;
}

export interface ValidationFailedResponse extends AxiosResponse {
  data: {
    detail: 'validation_failed';
    violations: ValidationViolation[];
  };
}

/**
 * Determines if validation violation exists for field.
 */
export function hasViolationForField(
  field: string,
  violations: ValidationViolation[],
) {
  return violations.find((v) => v.propertyPath === field) !== undefined;
}
