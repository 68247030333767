import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Theme,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { colors } from '../../config/theme';

import {
  hasViolationForField,
  ValidationViolation,
} from '../../utils/validation';
import {
  UserDTO,
  Fund,
  fundToMinimalFund,
  MinimalFund,
  assignableRole,
  userToUserDTO,
  User,
} from '../../types';
import FundRepository from '../funds/FundRepository';
import AppContext from '../../AppContext';
import { UserState } from '../../reducers/user';
import UserRepository from './UserRepository';
import { RoleInterface } from './Roles';

const fundRoles: (keyof RoleInterface)[] = [
  'ROLE_FUND_MANAGER',
  'ROLE_FUND_USER',
];

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    background: colors.white,
  },
  field: {
    marginBottom: theme.spacing(2),
  },
}));

type UserFormProps = {
  user?: User;
};

const UserForm = (props: UserFormProps) => {
  const { user: existingUser } = props;

  const notifications = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const { account } = useSelector((selector: { user: UserState }) => ({
    account: selector.user.account,
  }));

  const { roleViewManager } = useContext(AppContext);
  const userRepository = new UserRepository();

  const [funds, setFunds] = useState<MinimalFund[]>([]);
  const [selectedFund, setSelectedFund] = useState<MinimalFund | null>(
    existingUser?.fund || null,
  );
  const [violations, setViolations] = useState<ValidationViolation[]>([]);

  const isAdmin = roleViewManager.isAdminView();
  const hasCentralManagerPermissions =
    roleViewManager.isAdminView() || roleViewManager.isCentralManagerView();

  // preload the fund id if the user is a fund manager
  const initialFundId = hasCentralManagerPermissions
    ? null
    : account?.fund?.id || null;

  const initialUser = existingUser
    ? userToUserDTO(existingUser)
    : ({
        email: '',
        firstName: '',
        insertion: null,
        lastName: '',
        initialRole: 'fundUser',
        existingRoles: [],
        fundId: initialFundId,
      } as UserDTO);

  const [user, setUser] = useState<UserDTO>(initialUser);

  const isNewUser = user.id === undefined;

  // determine whether the fund is required, and whether it is currently editable
  let requireFund = false;
  let disableFundSelection = false;
  if (isNewUser) {
    requireFund = user.initialRole === 'fundUser';
  } else if (existingUser) {
    // although a fund is required for both a fund manager and a fund user,
    // it's only editable for a regular fund user to prevent complicated validation logic
    if (
      existingUser.roles.length ===
      existingUser.roles.filter((role) => fundRoles.includes(role)).length
    ) {
      requireFund = true;
      disableFundSelection = existingUser.roles.includes('ROLE_FUND_MANAGER');
    }
  }

  const handleFundChange = (fund: MinimalFund | null) => {
    setSelectedFund(fund);
    setUser({ ...user, fundId: fund?.id || '' });
  };

  const classes = useStyles();

  const handleRoleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newRole = event.target.value as assignableRole;
    setUser({
      ...user,
      initialRole: newRole,
      fundId: newRole === 'fundUser' ? user.fundId : null,
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const promise = user?.id
      ? userRepository.update(user)
      : userRepository.create(user);

    promise
      .then(() => {
        if (location.pathname.match(`^/account`)) {
          history.go(0);
        } else {
          history.push('/gebruikers');
        }
        notifications.enqueueSnackbar(
          `Gebruiker is succesvol ${user?.id ? 'gewijzigd' : 'aangemaakt'}!`,
          { variant: 'success' },
        );
      })
      .catch((err) => {
        const { data } = err.response;

        if ('violations' in data) {
          setViolations(data.violations);
        }

        notifications.enqueueSnackbar(
          `Er is iets fout gegaan bij het ${
            user?.id ? 'wijzigen' : 'aanmaken'
          } van de gebruiker.`,
          { variant: 'error' },
        );
      });
  };

  useEffect(() => {
    if (!hasCentralManagerPermissions) {
      return;
    }

    new FundRepository(false)
      .findBy(undefined, 1, 1e6)
      .then((res) => {
        const funds: Array<MinimalFund> = [];
        res.data.items.forEach((fund: Fund) => {
          funds.push(fundToMinimalFund(fund));
        });
        setFunds(funds);
      })
      .catch();
  }, []);

  return (
    <div className={classes.container}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Box mb={1}>
          <TextField
            label="E-mail"
            placeholder="email@adres.com"
            type="email"
            className={classes.field}
            value={user.email}
            error={hasViolationForField('email', violations)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setUser({ ...user, email: e.target.value });
            }}
            fullWidth
            required
          />
          <TextField
            label="Voornaam"
            className={classes.field}
            value={user.firstName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setUser({
                ...user,
                firstName: e.target.value,
              });
            }}
            fullWidth
            required
          />
          <TextField
            label="Tussenvoegsel"
            className={classes.field}
            value={user.insertion}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setUser({
                ...user,
                insertion: e.target.value,
              });
            }}
            fullWidth
          />
          <TextField
            label="Achternaam"
            className={classes.field}
            value={user.lastName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setUser({
                ...user,
                lastName: e.target.value,
              });
            }}
            fullWidth
            required
          />
        </Box>
        {isAdmin && !user.id && (
          <Box mb={1}>
            <FormControl>
              <FormLabel>Rol</FormLabel>
              <RadioGroup
                value={user.initialRole}
                name="action"
                onChange={handleRoleChange}
              >
                <FormControlLabel
                  control={<Radio />}
                  label="Admin"
                  value="admin"
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Centraal manager"
                  value="centralManager"
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Fondsmedewerker"
                  value="fundUser"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        )}
        {requireFund && (
          <Box mb={1}>
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Selecteer een fonds..."
                  variant="outlined"
                  error={hasViolationForField('fundId', violations)}
                  fullWidth
                />
              )}
              getOptionLabel={(fund) => fund.name}
              options={funds}
              autoComplete
              onChange={(event: any, newValue: MinimalFund | null) => {
                handleFundChange(newValue);
              }}
              value={selectedFund}
              noOptionsText="Geen fondsen gevonden"
              disabled={disableFundSelection}
            />
          </Box>
        )}
        <Button
          variant="contained"
          type="submit"
          color="primary"
          startIcon={<FontAwesomeIcon icon={['fad', 'save']} />}
        >
          Opslaan
        </Button>
      </form>
    </div>
  );
};

export default UserForm;
