import React, { Suspense } from 'react';
import { TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { red } from '@mui/material/colors';
import Loader from './Loader';
import ConditionalWrapper from './ConditionalWrapper';

interface HtmlEditorProps {
  data?: string;
  onChange?: (event: any, editor: any) => void;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
}

const CKEditor = React.lazy(() => import('./CKEditor'));

const useStyles = makeStyles((theme: Theme) => ({
  errorWrapper: {
    border: `2px solid ${red[500]}`,
    borderRadius: theme.shape.borderRadius,
  },
}));

const HtmlEditor = ({
  data = '',
  placeholder = '',
  onChange,
  disabled = false,
  error = false,
}: HtmlEditorProps) => {
  const classes = useStyles();
  const textarea: boolean = document.body.classList.contains('form-pdf-view');

  if (textarea) {
    return (
      <TextField
        multiline
        rows={6}
        placeholder={placeholder}
        variant="outlined"
        defaultValue={data}
      />
    );
  }

  return (
    <Suspense fallback={<Loader inline />}>
      <ConditionalWrapper
        condition={error}
        wrapper={(children) => (
          <div className={classes.errorWrapper}>{children}</div>
        )}
      >
        <CKEditor
          data={data}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
        />
      </ConditionalWrapper>
    </Suspense>
  );
};

export default HtmlEditor;
