import React from 'react';
import { Route as DOMRoute, Switch, matchPath } from 'react-router-dom';

import Route from './Route';
import { User } from '../types';

class Router {
  routes: Route[] = [];

  notFoundComponent: JSX.Element | null = null;

  addRoute(node: Route) {
    this.routes.push(node);
  }

  getNodeByPath(path: string): Route | null {
    let match = null;

    this.getRoutes().forEach((route) => {
      if (
        matchPath(path, { path: route.getPath(), exact: true, strict: false })
      ) {
        match = route;
      }
    });

    return match;
  }

  getSwitch(user?: User | null) {
    const routes = this.getRoutes()
      .filter((route) => {
        if (route.isAnonymous) {
          return !user;
        }

        return (
          !route.isRequiringLogin || (user && this.canViewRoute(user, route))
        );
      })
      .map((route) => (
        <DOMRoute
          key={`route-${route.getTitle()}`}
          exact
          path={route.getPath()}
        >
          {route.getElement()}
        </DOMRoute>
      ));

    return (
      <Switch>
        {routes}
        {this.notFoundComponent}
      </Switch>
    );
  }

  setNotFoundComponent(element: JSX.Element) {
    this.notFoundComponent = element;
    return this;
  }

  private getRoutes() {
    let routes: Route[] = [];

    this.routes.forEach((route) => {
      routes = routes.concat(this.getChildrenOfNode(route));
    });

    return routes;
  }

  private getChildrenOfNode(node: Route, result: Route[] = []) {
    result.push(node);
    const children = node.getChildren();
    if (children.length > 0) {
      children.forEach((child) => this.getChildrenOfNode(child, result));
    }

    return result;
  }

  private canViewRoute(user: User, route: Route) {
    const allowedRoles = route.getRoles();

    if (allowedRoles.length === 0) {
      return true;
    }

    for (let i = 0; i < user.roles.length; i++) {
      if (allowedRoles.includes(user.roles[i])) {
        return true;
      }
    }

    return false;
  }
}

export default new Router();
