// @ts-ignore
import Flow from '@flowjs/flow.js';
// eslint-disable-next-line import/no-unresolved
import { Flow as IFlow } from 'flowjs';
import { ApiFilterCriteria, File } from '../types';

export function getFileURL(file: File) {
  let url = `${process.env.REACT_APP_API_URL}/api/file/${file.id}`;

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('token')) {
    url += `?token=${urlParams.get('token')}`;
  }

  return url;
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function uniqueEntries(value: any, index: Number, self: Array<any>) {
  return self.indexOf(value) === index;
}

/**
 * Parses API filter criteria to a params object.
 *
 * @param criteria
 * @param params
 */
export function parseCriteria(
  criteria: ApiFilterCriteria | undefined,
  params: {
    [key: string]: string | number | null | boolean;
  },
) {
  if (!criteria) {
    return params;
  }

  const result: {
    [key: string]: string | number | null | boolean;
  } = {
    ...params,
    query: criteria.query || null,
  };

  if (criteria.filters) {
    Object.entries(criteria.filters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        result[key] = value.map((v) => v.value).join(',');
      } else {
        result[key] = value;
      }
    });
  }

  if (criteria.order) {
    criteria.order.forEach((item) => {
      result[`order[${item.field}]`] = item.order;
    });
  }

  return result;
}

/**
 * Create a new Flow instance for uploading files.
 */
export function createFlow(): IFlow {
  const query: {
    token?: string;
  } = {};

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('token')) {
    query.token = urlParams.get('token') as string;
  }

  return new Flow({
    target: `${process.env.REACT_APP_API_URL}/api/file`,
    query,
    withCredentials: true,
  });
}
