import React, { useContext, useMemo, useState } from 'react';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import DataTable from '../../components/DataTable';
import { Fund } from '../../types';
import SearchContext from '../../components/search/SearchContext';

import AppContext from '../../AppContext';
import FundRepository from './FundRepository';
import SearchInput from '../../components/search/SearchInput';
import PageContainer from '../../components/PageContainer';
import { UserState } from '../../reducers/user';
import useFundPermissions, { canEditFund } from './fund-permissions';

const FundOverview = () => {
  const history = useHistory();

  const { account } = useSelector((selector: { user: UserState }) => ({
    account: selector.user.account,
  }));

  const [query, setQuery] = useState<string>('');
  const { roleViewManager } = useContext(AppContext);

  // extract fund permissions that are not fund-specific
  const { canCreate, canView, canDelete } = useFundPermissions();

  const searchContextValue = useMemo(
    () => ({ query, setQuery }),
    [query, setQuery],
  );

  const repository = useMemo(() => new FundRepository(false), []);

  const columns = [
    {
      name: 'Naam',
      field: 'Website',
      sortable: true,
      render: (fund: Fund) => {
        const title = (
          <>
            <Box ml={1}>{fund.name}</Box>
          </>
        );

        if (canView) {
          return <Link to={`/fondsen/${fund.id}`}>{title}</Link>;
        }

        return title;
      },
    },
  ];

  const itemActions = (fund: Fund, className: string) => {
    const canEdit = canEditFund(fund?.id || '', roleViewManager, account);

    return (
      <div>
        {canEdit && (
          <Tooltip title="Fonds bewerken">
            <IconButton
              size="small"
              component={Link}
              to={`/fondsen/${fund.id}/bewerken`}
              className={className}
            >
              <FontAwesomeIcon icon={['fal', 'edit']} />
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  };

  const actions = (
    <Box display="flex" alignItems="center">
      <Box mr={3}>
        <SearchInput id="fund-search-input" persistQuery />
      </Box>
      {canCreate && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push('/fondsen/aanmaken')}
          startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
        >
          Nieuw fonds
        </Button>
      )}
    </Box>
  );

  return (
    <SearchContext.Provider value={searchContextValue}>
      <PageContainer title="Fondsen" actions={actions}>
        <Box mt={3}>
          <DataTable
            id="fonds-overview"
            repository={repository}
            columns={columns}
            actions={itemActions}
            searchable
            deletable={canDelete}
            deleteItemMessage={(fund: Fund) => {
              return `Weet je zeker dat je ${
                fund ? fund.name : 'dit project'
              } wilt verwijderen?`;
            }}
          />
        </Box>
      </PageContainer>
    </SearchContext.Provider>
  );
};

export default FundOverview;
