import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { green, red } from '@mui/material/colors';
import { Theme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface PasswordValidationRule {
  name: string;
  validate: (password: string) => boolean;
}

interface PasswordValidatorProps {
  password: string;
  onValidate: (isValid: boolean) => void;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    color: red[700],
  },
  valid: {
    color: green[700],
  },
  list: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const PasswordValidator = (props: PasswordValidatorProps) => {
  const { password, onValidate, className } = props;
  const classes = useStyles();

  const rules: PasswordValidationRule[] = [
    {
      name: 'Minimaal 8 tekens',
      validate: (password: string) => password.length >= 8,
    },
    {
      name: 'Hoofdletters en kleine letters',
      validate: (password: string) =>
        RegExp(/[a-z]/).test(password) && RegExp(/[A-Z]/).test(password),
    },
    {
      name: 'Minimaal 1 cijfer',
      validate: (password: string) => RegExp(/[0-9]/).test(password),
    },
    {
      name: 'Minimaal 1 speciaal teken (!,@,?,%,^,&,*,$)',
      validate: (password: string) => RegExp(/[!@#?%^&*$()]/).test(password),
    },
  ];

  useEffect(() => {
    let isValid = true;
    rules.forEach((rule) => {
      if (!rule.validate(password)) {
        isValid = false;
      }
    });
    onValidate(isValid);
  }, [password]);

  return (
    <div className={className}>
      Het wachtwoord moet aan de volgende eisen voldoen:
      <div className={classes.list}>
        {rules.map((rule) => {
          const isValid = rule.validate(password);

          let className = '';
          let icon: any = 'circle';
          if (password.length > 0) {
            className = isValid ? classes.valid : classes.error;
            icon = isValid ? 'check' : 'times';
          }

          return (
            <div className={className}>
              <FontAwesomeIcon
                icon={['fal', icon]}
                className={classes.icon}
                fixedWidth
              />
              {rule.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PasswordValidator;
