import React from 'react';

import { Router, Route } from '../../routing';
import UsersOverview from './UsersOverview';
import CreateUser from './CreateUser';
import EditUser from './EditUser';
import MyAccount from './MyAccount';
import ForgotPassword from './ForgotPassword';
import UserActivation from './UserActivation';

const users = new Route('Gebruikers', '/gebruikers', <UsersOverview />).role([
  'ROLE_ADMIN',
  'ROLE_CENTRAL_MANAGER',
  'ROLE_FUND_MANAGER',
]);
users.addChild(new Route('Gebruiker aanmaken', '/aanmaken', <CreateUser />));
users.addChild(new Route('Gebruiker bewerken', '/:id/bewerken', <EditUser />));
Router.addRoute(users);

const account = new Route('Profiel', '/account', <MyAccount />).requireLogin();
Router.addRoute(account);

Router.addRoute(
  new Route(
    'Wachtwoord vergeten',
    '/wachtwoord-vergeten',
    <ForgotPassword />,
  ).anonymous(),
);
Router.addRoute(
  new Route('Account activeren', '/activeren', <UserActivation />).anonymous(),
);
