import React from 'react';
import { useSelector } from 'react-redux';

import { UserState } from '../../reducers/user';
import EditUser from './EditUser';

const MyAccount = () => {
  const { account } = useSelector((selector: { user: UserState }) => ({
    account: selector.user.account,
  }));

  return <EditUser id={account?.id} />;
};

export default MyAccount;
