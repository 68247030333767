import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@mui/lab';
import { debounce, TextField } from '@mui/material';
import { AxiosResponse } from 'axios';
import { ApiFilterCriteria, Municipality } from '../types';
import ApiClient from '../api/ApiClient';
import { parseCriteria } from '../utils/common';

interface MunicipalityAutocompleteProps {
  initialMunicipalities?: Municipality[];
  onChange: (municipalities: Municipality[]) => void;
}

class MunicipalityRepository {
  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
  ): Promise<AxiosResponse<Municipality[]>> {
    const params = parseCriteria(criteria, { page: page || 1, perPage: 10 });

    return ApiClient.get('/api/geo/municipalities', params);
  }
}

const MunicipalityAutocomplete = (props: MunicipalityAutocompleteProps) => {
  const { initialMunicipalities, onChange } = props;
  const [selectedMunicipalities, setSelectedMunicipalities] = useState<
    Municipality[]
  >(initialMunicipalities || []);
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<Municipality[]>([]);
  const repository = new MunicipalityRepository();

  const getOptions = debounce((query: string) => {
    const criteria: ApiFilterCriteria = { query };

    repository.findBy(criteria).then((response) => {
      setOptions(response.data);
    });
  }, 200);

  useEffect(() => {
    if (inputValue.length >= 3) {
      getOptions(inputValue);
    }
  }, [inputValue, selectedMunicipalities]);

  useEffect(() => {
    if (onChange) {
      onChange(selectedMunicipalities);
    }
  }, [selectedMunicipalities]);

  return (
    <Autocomplete
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label="Zoek een gemeente..."
          variant="outlined"
          fullWidth
        />
      )}
      getOptionLabel={(option) => option.name}
      options={options}
      autoComplete
      onChange={(event: any, newValue: Municipality[]) => {
        setInputValue('');
        setOptions([]);
        setSelectedMunicipalities(newValue);
      }}
      value={selectedMunicipalities}
      noOptionsText="Geen gemeente gevonden"
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      multiple
    />
  );
};

export default MunicipalityAutocomplete;
