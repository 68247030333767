import { AxiosResponse } from 'axios';

import ApiClient from '../../api/ApiClient';
import {
  ApiFilterCriteria,
  CollectionResponse,
  UserDTO,
  EventLog,
  HydraCollectionResponse,
  Repository,
  TwoFactorMethod,
  User,
} from '../../types';
import { parseCriteria } from '../../utils/common';
import { ValidationFailedResponse } from '../../utils/validation';

class UserRepository implements Repository<User> {
  find(id: string): Promise<AxiosResponse<User>> {
    return ApiClient.get(`/api/users/${id}`);
  }

  getAccount(): Promise<AxiosResponse<User>> {
    return ApiClient.get('/api/users/account');
  }

  getLoginEvents(id: string): Promise<HydraCollectionResponse<EventLog>> {
    return ApiClient.get(`/api/event-logs/users/${id}/login`);
  }

  findBy(
    criteria?: ApiFilterCriteria,
    page: number = 1,
    perPage: number = 10,
  ): Promise<CollectionResponse<User>> {
    const params = parseCriteria(criteria, {
      page,
      perPage,
    });
    return ApiClient.get('/api/users', params);
  }

  update(
    user: UserDTO,
  ): Promise<AxiosResponse<User> | ValidationFailedResponse> {
    return ApiClient.put(`/api/users/${user.id}`, user);
  }

  create(
    user: UserDTO,
  ): Promise<AxiosResponse<User> | ValidationFailedResponse> {
    return ApiClient.post('/api/users', user);
  }

  delete(id: string) {
    return ApiClient.delete(`/api/users/${id}`);
  }

  activateUser(user: User) {
    return ApiClient.post('/api/users/activated', [user.id]);
  }

  updateTwoFactorMethod(
    user: User,
    method: TwoFactorMethod | null,
    twoFactorCode: string | null,
  ) {
    return ApiClient.post(`/api/users/${user.id}/two-factor`, {
      method,
      twoFactorCode,
    });
  }

  getTwoFactorQrCode() {
    return ApiClient.get('/api/users/qr-code');
  }
}

export default UserRepository;
