import React, { useContext, useMemo, useState } from 'react';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import DataTable from '../../components/DataTable';
import { Category, Project } from '../../types';
import StatusIndicator from '../../components/StatusIndicator';
import SearchContext from '../../components/search/SearchContext';

import AppContext from '../../AppContext';
import ProjectRepository from './ProjectRepository';
import SearchInput from '../../components/search/SearchInput';
import PageContainer from '../../components/PageContainer';
import CategoryRepository from './CategoryRepository';

const ProjectOverview = () => {
  const [query, setQuery] = useState<string>('');
  const { roleViewManager } = useContext(AppContext);

  const searchContextValue = useMemo(
    () => ({ query, setQuery }),
    [query, setQuery],
  );

  const projectRepository = useMemo(
    () => new ProjectRepository(roleViewManager.getUser().fund?.id),
    [],
  );

  const columns = [
    {
      field: 'title',
      name: 'Titel',
      sortable: true,
      render: (project: Project) => {
        const title = (
          <>
            <StatusIndicator indicator={!project.concept ? 'green' : 'red'} />
            <Box ml={1}>{project.title}</Box>
          </>
        );

        return <Link to={`/projecten/${project.id}`}>{title}</Link>;
      },
    },
    {
      name: 'Categorieën',
      field: 'categories',
      filter: {
        type: 'checkbox' as const,
        options: async () => {
          const response = await new CategoryRepository().findBy();
          return response.data.items.map((category) => ({
            value: category.id!,
            label: category.name!,
          }));
        },
      },
      render: (project: Project) => {
        if (!project.categories) {
          return '-';
        }

        return project.categories
          .map((category: Category) => category.name)
          .join(', ');
      },
    },
    {
      name: 'Aangemaakt',
      field: 'created',
      sortable: true,
      render: (project: Project) =>
        format(new Date(project.created), 'dd-MM-yyyy HH:mm'),
    },
    {
      name: 'Laatst bewerkt',
      field: 'updated',
      sortable: true,
      render: (project: Project) =>
        format(new Date(project.updated), 'dd-MM-yyyy HH:mm'),
    },
  ];

  const itemActions = (project: Project, className: string) => (
    <div>
      <Tooltip title="Project bewerken">
        <IconButton
          size="small"
          component={Link}
          to={`/projecten/${project.id}/bewerken`}
          className={className}
        >
          <FontAwesomeIcon icon={['fal', 'edit']} />
        </IconButton>
      </Tooltip>
    </div>
  );

  const actions = (
    <Box display="flex" alignItems="center">
      <Box mr={3}>
        <SearchInput id="project-search-input" persistQuery />
      </Box>
      <Box mr={3}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/projecten/aanmaken"
          startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
        >
          Nieuw project
        </Button>
      </Box>
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = `${process.env.REACT_APP_API_URL}/api/projects/export/csv`;
          }}
          startIcon={<FontAwesomeIcon icon={['fal', 'file-export']} />}
        >
          Exporteren naar CSV
        </Button>
      </Box>
    </Box>
  );

  return (
    <SearchContext.Provider value={searchContextValue}>
      <PageContainer title="Projecten" actions={actions}>
        <Box mt={3}>
          <DataTable
            id="project-overview"
            repository={projectRepository}
            columns={columns}
            actions={itemActions}
            searchable
            deletable
            deleteItemMessage={(project: Project) => {
              return `Weet je zeker dat je ${
                project ? project.title : 'dit project'
              } wilt verwijderen?`;
            }}
          />
        </Box>
      </PageContainer>
    </SearchContext.Provider>
  );
};

export default ProjectOverview;
