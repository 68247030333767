import React from 'react';
import { Route, Router } from '../../routing';
import ProjectOverview from './ProjectOverview';
import CreateProject from './CreateProject';
import ViewProject from './ViewProject';
import EditProject from './EditProject';
import CategoryOverview from './CategoryOverview';
import SaveCategory from './SaveCategory';

Router.addRoute(
  new Route('Projecten', '/', <ProjectOverview />).requireLogin(),
);

const projects = new Route(
  'Projecten',
  '/projecten',
  <ProjectOverview />,
).requireLogin();
projects.addChild(
  new Route('Project aanmaken', '/aanmaken', <CreateProject />),
);
projects.addChild(new Route('Project bekijken', '/:id', <ViewProject />));
projects.addChild(
  new Route('Project bewerken', '/:id/bewerken', <EditProject />),
);
Router.addRoute(projects);

// eslint-disable-next-line import/prefer-default-export
export const CATEGORY_OVERVIEW = '/categorieen';

const categoryOverview = new Route(
  'Categorieën',
  CATEGORY_OVERVIEW,
  <CategoryOverview />,
).role(['ROLE_ADMIN', 'ROLE_CENTRAL_MANAGER']);
Router.addRoute(categoryOverview);

const createCategory = new Route(
  'Categorie aanmaken',
  '/aanmaken',
  <SaveCategory />,
).role(['ROLE_ADMIN', 'ROLE_CENTRAL_MANAGER']);
categoryOverview.addChild(createCategory);

const editCategory = new Route(
  'Categorie bewerken',
  '/:id',
  <SaveCategory />,
).role(['ROLE_ADMIN', 'ROLE_CENTRAL_MANAGER']);
categoryOverview.addChild(editCategory);
