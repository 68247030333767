import React, { ChangeEvent, useState } from 'react';
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@mui/styles';
import { colors } from '../../config/theme';

interface PasswordInputProps {
  id: string;
  error: boolean | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  className?: string;
  outlined?: boolean;
}

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
  inputLabelOutline: {
    background: colors.white,
    '&.Mui-focused': {
      paddingLeft: 4,
      paddingRight: 4,
    },
  },
}));

const PasswordInput = (props: PasswordInputProps) => {
  const classes = useStyles();
  const { id, error, onChange, label, className, outlined } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleToggle = () => setShowPassword(!showPassword);

  const adornment = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleToggle}
        size="large"
      >
        <FontAwesomeIcon
          icon={['fal', showPassword ? 'eye-slash' : 'eye']}
          fixedWidth
        />
      </IconButton>
    </InputAdornment>
  );

  return outlined ? (
    <FormControl
      variant="outlined"
      className={`${className} ${classes.formControl}`}
    >
      <InputLabel
        htmlFor={id}
        error={error}
        className={classes.inputLabelOutline}
      >
        {label || 'Wachtwoord'}
      </InputLabel>
      <OutlinedInput
        id={id}
        data-testid={id}
        type={showPassword ? 'text' : 'password'}
        onChange={onChange}
        error={error}
        endAdornment={adornment}
        fullWidth
        notched
      />
    </FormControl>
  ) : (
    <FormControl className={`${className} ${classes.formControl}`}>
      <InputLabel htmlFor={id}>{label || 'Wachtwoord'}</InputLabel>
      <Input
        id={id}
        data-testid={id}
        type={showPassword ? 'text' : 'password'}
        onChange={onChange}
        error={error}
        endAdornment={adornment}
        fullWidth
      />
    </FormControl>
  );
};

export default PasswordInput;
