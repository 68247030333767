import React from 'react';

import ErrorPage from './ErrorPage';

const Error403 = () => (
  <ErrorPage
    title="Oeps!"
    description="Je hebt geen toestemming om deze pagina te bekijken."
  />
);

export default Error403;
