import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import PageContainer from '../../components/PageContainer';
import FundRepository from './FundRepository';
import { Fund, FundDto, Municipality, Place } from '../../types';
import FundForm from './FundForm';
import Loader from '../../components/Loader';
import { ReactComponent as Circle } from '../../assets/images/circle.svg';

const useStyles = makeStyles((theme: Theme) => ({
  circle: {
    position: 'absolute',
    right: '-600px',
    top: 'calc(50% - 600px)',
    [theme.breakpoints.down('md')]: {
      right: '-800px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export const fundToDto = (fund: Fund) => {
  return {
    id: fund.id,
    name: fund.name,
    websiteUrl: fund?.websiteUrl || '',
    managerId: fund?.manager?.id || '',
    phoneNumber: fund?.phoneNumber,
    email: fund.email,
    places: fund.places,
    placeCodes: fund.places.map((place: Place) => place.code),
    municipalities: fund.municipalities,
    municipalityCodes: fund.municipalities.map(
      (municipality: Municipality) => municipality.code,
    ),
    logo: fund?.logo || undefined,
    logoId: fund?.logo?.id || '',
  } as FundDto;
};

const EditFund = () => {
  const fundRepository = new FundRepository();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [fund, setFund] = useState<FundDto | null>(null);
  const classes = useStyles();

  useEffect(() => {
    fundRepository
      .find(id)
      .then((res) => setFund(fundToDto(res.data)))
      .catch(() => history.push('/fondsen'));
  }, []);

  if (!fund) {
    return <Loader />;
  }

  return (
    <div>
      <Circle className={classes.circle} />
      <PageContainer title="Fonds bewerken">
        <FundForm fund={fund} />
      </PageContainer>
    </div>
  );
};

export default EditFund;
