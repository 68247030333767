import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormLabel,
  Grid,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import validator from 'validator';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import { Alert } from '@mui/lab';

import UserAuthenticator from './api/UserAuthenticator';
import Home from './Home';
import PasswordInput from './components/password/PasswordInput';
import ApiClient from './api/ApiClient';
import { UserState } from './reducers/user';
import { ReactComponent as Circle } from './assets/images/circle.svg';

type LoginState = {
  email: string;
  password: string;
  errors: {
    email?: boolean | undefined;
    password?: boolean | undefined;
    twoFactorCode?: boolean | undefined;
  };
  isInactive: boolean;
  twoFactorCode: string;
};

export const useStyles = makeStyles((theme: Theme) => ({
  form: {
    overflow: 'hidden',
    width: '100%',
    maxWidth: 375,
    minWidth: 375,
    borderRadius: 4,
    padding: theme.spacing(3),
    boxShadow: '1px 1px 2px 1px rgba(0, 0, 0, 0.09)',
    background: '#FFF',
  },
  formTitle: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
    margin: -theme.spacing(3).slice(0, -2),
    marginBottom: theme.spacing(2),
    padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
  },
  error: {
    marginBottom: theme.spacing(2),
  },
  circle: {
    position: 'absolute',
    right: '-600px',
    top: '-200px',
    zIndex: -1,
    [theme.breakpoints.down('md')]: {
      right: '-800px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const Login = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const notifications = useSnackbar();
  const requestTwoFactorCode = useSelector(
    (selector: { user: UserState }) => selector.user.requestTwoFactorCode,
  );
  const [state, setState] = useState<LoginState>({
    email: '',
    password: '',
    errors: {},
    isInactive: false,
    twoFactorCode: '',
  });
  const { email, password, errors, isInactive, twoFactorCode } = state;

  const handleFailure = (err: any) => {
    errors.email = true;
    errors.password = true;

    setState({
      ...state,
      errors,
      isInactive:
        err.response !== undefined
          ? err.response.data.error === 'inactive_user'
          : false,
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const authenticator = new UserAuthenticator(dispatch);

    if (requestTwoFactorCode) {
      authenticator.checkTwoFactorCode(twoFactorCode, (err: any) => {
        errors.twoFactorCode = true;
        setState({
          ...state,
          errors,
          isInactive:
            err.response !== undefined
              ? err.response.data.error === 'inactive_user'
              : false,
        });
      });

      return;
    }

    errors.email = !validator.isEmail(email);
    errors.password = validator.isEmpty(password);

    setState({ ...state, errors });

    if (!validator.isEmail(email || '') || validator.isEmpty(password || '')) {
      return;
    }

    authenticator.login(email, password, handleFailure);
  };

  const changeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, email: e.target.value });
  };

  const changePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, password: e.target.value });
  };

  const changeTwoFactorCode = (e: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, twoFactorCode: e.target.value });
  };

  const handleCancelTwoFactorRequest = () => {
    new UserAuthenticator(dispatch).logout();
  };

  const resendActivationMail = (e: React.MouseEvent) => {
    e.preventDefault();
    ApiClient.sendActivationMail(email)
      .then(() => {
        notifications.enqueueSnackbar(
          'De activatie mail is opnieuw verzonden.',
          { variant: 'success' },
        );
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het versturen van de activate mail.',
          { variant: 'error' },
        );
      });
  };

  return (
    <Home>
      <Circle className={classes.circle} />
      <Box className={classes.form}>
        <Typography variant="h6" className={classes.formTitle}>
          Inloggen
        </Typography>
        <form onSubmit={handleSubmit}>
          {isInactive && (
            <Alert severity="error" className={classes.error}>
              Uw account is nog niet geactiveerd.
              <Button onClick={resendActivationMail}>Opnieuw versturen.</Button>
            </Alert>
          )}
          {requestTwoFactorCode && (
            <Box mb={2}>
              <FormLabel>Voer hier de verificatiecode in:</FormLabel>
              <TextField
                type="text"
                value={twoFactorCode || ''}
                onChange={changeTwoFactorCode}
                error={errors.twoFactorCode}
                fullWidth
              />
            </Box>
          )}
          {!requestTwoFactorCode && (
            <>
              <TextField
                type="email"
                data-testid="email"
                value={email || ''}
                onChange={changeEmail}
                error={errors.email}
                label="Gebruikersnaam"
                fullWidth
              />

              <Box mt={2} mb={3}>
                <PasswordInput
                  id="password"
                  data-testid="password"
                  onChange={changePassword}
                  error={errors.password}
                  outlined
                />
              </Box>
              <Box mb={2}>
                <Button
                  variant="text"
                  size="small"
                  onClick={() => history.push('/wachtwoord-vergeten')}
                >
                  Wachtwoord vergeten?
                </Button>
              </Box>
            </>
          )}
          <Grid container spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                data-testid="submit"
              >
                Inloggen
              </Button>
            </Grid>
            {requestTwoFactorCode && (
              <Grid item>
                <Button variant="text" onClick={handleCancelTwoFactorRequest}>
                  Annuleren
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </Box>
    </Home>
  );
};

export default Login;
