import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@mui/lab';
import { debounce, TextField } from '@mui/material';
import { AxiosResponse } from 'axios';
import { ApiFilterCriteria, Place } from '../types';
import ApiClient from '../api/ApiClient';
import { parseCriteria } from '../utils/common';

interface PlaceAutocompleteProps {
  initialPlaces?: Place[];
  onChange: (places: Place[]) => void;
}

class PlaceRepository {
  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
  ): Promise<AxiosResponse<Place[]>> {
    const params = parseCriteria(criteria, { page: page || 1, perPage: 10 });

    return ApiClient.get('/api/geo/places', params);
  }
}

const PlaceAutocomplete = (props: PlaceAutocompleteProps) => {
  const { initialPlaces, onChange } = props;
  const [selectedPlaces, setSelectedPlaces] = useState<Place[]>(
    initialPlaces || [],
  );
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<Place[]>([]);
  const placeRepository = new PlaceRepository();

  const getOptions = debounce((query: string) => {
    const criteria: ApiFilterCriteria = { query };

    placeRepository.findBy(criteria).then((response) => {
      setOptions(response.data);
    });
  }, 200);

  useEffect(() => {
    if (inputValue.length >= 3) {
      getOptions(inputValue);
    }
  }, [inputValue, selectedPlaces]);

  useEffect(() => {
    if (onChange) {
      onChange(selectedPlaces);
    }
  }, [selectedPlaces]);

  return (
    <Autocomplete
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label="Zoek een plaats..."
          variant="outlined"
          fullWidth
        />
      )}
      getOptionLabel={(option) => option.name}
      options={options}
      autoComplete
      onChange={(event: any, places: Place[]) => {
        setInputValue('');
        setOptions([]);
        setSelectedPlaces(places);
      }}
      value={selectedPlaces}
      noOptionsText="Geen plaats gevonden"
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      multiple
    />
  );
};

export default PlaceAutocomplete;
