import React from 'react';

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import PageContainer from '../../components/PageContainer';
import FundForm from './FundForm';
import { ReactComponent as Circle } from '../../assets/images/circle.svg';

const useStyles = makeStyles((theme: Theme) => ({
  circle: {
    position: 'absolute',
    right: '-600px',
    top: 'calc(50% - 600px)',
    [theme.breakpoints.down('md')]: {
      right: '-800px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const CreateFund = () => {
  const classes = useStyles();

  return (
    <div>
      <Circle className={classes.circle} />
      <PageContainer title="Fonds aanmaken">
        <FundForm />
      </PageContainer>
    </div>
  );
};

export default CreateFund;
