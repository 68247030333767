import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Box, Button, Theme } from '@mui/material';
import validator from 'validator';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApiClient from '../../api/ApiClient';
import PasswordInput from '../../components/password/PasswordInput';
import PasswordValidator from '../../components/password/PasswordValidator';
import { User } from '../../types';

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    color: theme.palette.text.primary,
  },
  passwordValidator: {
    fontSize: '.8em',
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(3),
  },
  fieldPassword: {
    marginBottom: theme.spacing(3),
    // Work-around for bug that input border is visible behind the label
    // See e.g. https://github.com/mui-org/material-ui/issues/14530
    '& label': {
      paddingLeft: '5px',
      marginLeft: '-5px',
      paddingRight: '8px',
      marginRight: '-8px',
      backgroundColor: '#fff',
    },
  },
  form: {
    color: theme.palette.text.primary,
  },
}));

type State = {
  oldPassword: string;
  password: string;
  passwordDirty: boolean;
  confirmPassword: string;
  errors: {
    oldPassword?: boolean | undefined;
    password?: boolean | undefined;
    confirmPassword?: boolean | undefined;
  };
};

const ChangePasswordForm = (props: { user: User }) => {
  const { user } = props;

  const { account } = useSelector((selector: { user: { account: User } }) => ({
    account: selector.user.account,
  }));
  const isAdmin = account.roles.includes('ROLE_ADMIN');

  const classes = useStyles();
  const notifications = useSnackbar();

  const [state, setState] = useState<State>({
    oldPassword: '',
    password: '',
    passwordDirty: false,
    confirmPassword: '',
    errors: {},
  });
  const { oldPassword, password, passwordDirty, confirmPassword, errors } =
    state;

  const isFormValid = (): boolean =>
    (isAdmin || !!oldPassword) &&
    !!password &&
    !!confirmPassword &&
    !(errors.oldPassword || errors.password || errors.confirmPassword);

  const onChangeOldPassword = (e: ChangeEvent<HTMLInputElement>) => {
    state.errors.oldPassword = !e.target.value.length;
    setState({ ...state, oldPassword: e.target.value });
  };

  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    state.password = e.target.value;
    state.passwordDirty = true;
    state.errors.confirmPassword = e.target.value !== confirmPassword;
    setState({ ...state });
  };

  const onValidatePassword = (isValid: boolean) => {
    state.errors.password = passwordDirty && !isValid;
    setState({ ...state });
  };

  const onChangeConfirmPassword = (e: ChangeEvent<HTMLInputElement>) => {
    state.confirmPassword = e.target.value;
    state.errors.confirmPassword = e.target.value !== password;
    setState({ ...state });
  };

  const onSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    errors.oldPassword = !isAdmin && validator.isEmpty(oldPassword);
    errors.password = validator.isEmpty(password);
    errors.confirmPassword = validator.isEmpty(confirmPassword);
    setState({ ...state, errors });

    if (!isFormValid()) {
      return;
    }

    const data = new FormData();
    data.append('oldPassword', oldPassword);
    data.append('password', password);

    ApiClient.post(`/api/users/${user.id}/change-password`, data, {})
      .then(() => {
        notifications.enqueueSnackbar('Het wachtwoord is gewijzigd', {
          variant: 'success',
        });
      })
      .catch((err) => {
        if (err.response.data.error === 'old_password_incorrect') {
          state.errors.oldPassword = true;
          setState({ ...state });
          notifications.enqueueSnackbar(
            'Het huidige wachtwoord is niet correct!',
            { variant: 'error' },
          );
        } else if (err.response.data.error === 'invalid_password') {
          state.errors.password = true;
          state.errors.confirmPassword = true;
          setState({ ...state });
          notifications.enqueueSnackbar(
            'Het nieuwe wachtwoord is niet geldig!',
            { variant: 'error' },
          );
        } else {
          notifications.enqueueSnackbar('Wachtwoord wijzigen mislukt!', {
            variant: 'error',
          });
        }
      });
  };

  return (
    <Box>
      <form onSubmit={onSubmitForm} className={classes.form}>
        {!isAdmin && (
          <>
            <PasswordInput
              id="oldPassword"
              data-testid="oldPassword"
              onChange={onChangeOldPassword}
              error={errors.oldPassword}
              outlined
              className={classes.fieldPassword}
              label="Oude wachtwoord"
            />
          </>
        )}

        <PasswordInput
          id="password"
          data-testid="password"
          onChange={onChangePassword}
          error={errors.password}
          outlined
          className={classes.fieldPassword}
          label="Nieuwe wachtwoord"
        />
        <PasswordValidator
          password={password}
          onValidate={onValidatePassword}
          className={classes.passwordValidator}
        />

        <PasswordInput
          id="confirm-password"
          data-testid="confirmPassword"
          onChange={onChangeConfirmPassword}
          error={errors.confirmPassword}
          outlined
          className={classes.fieldPassword}
          label="Bevestig wachtwoord"
        />

        <Button
          variant="contained"
          disabled={!isFormValid()}
          color="primary"
          type="submit"
          data-testid="submit"
          startIcon={<FontAwesomeIcon icon={['fad', 'save']} />}
        >
          Wijzigen
        </Button>
      </form>
    </Box>
  );
};

export default ChangePasswordForm;
