import { AxiosResponse } from 'axios';

import ApiClient from '../../api/ApiClient';
import {
  ApiFilterCriteria,
  Repository,
  Project,
  CollectionResponse,
  SaveProjectDTO,
} from '../../types';
import { parseCriteria } from '../../utils/common';
import { ValidationFailedResponse } from '../../utils/validation';

class ProjectRepository implements Repository<Project> {
  private readonly fundId: string | null;

  constructor(fundId: string | null = null) {
    this.fundId = fundId;
  }

  find(id: string): Promise<AxiosResponse<Project>> {
    return ApiClient.get(`/api/projects/${id}`);
  }

  update(
    project: SaveProjectDTO,
  ): Promise<AxiosResponse<Project> | ValidationFailedResponse> {
    return ApiClient.put(`/api/projects/${project.id}`, project);
  }

  create(
    project: SaveProjectDTO,
  ): Promise<AxiosResponse<Project> | ValidationFailedResponse> {
    return ApiClient.post('/api/projects', project);
  }

  delete(id: string) {
    if (this.fundId) {
      return ApiClient.delete(`/api/funds/${this.fundId}/projects/${id}`);
    }
    return ApiClient.delete(`/api/projects/${id}`);
  }

  findBy(
    criteria?: ApiFilterCriteria,
    page: number = 1,
    perPage: number = 10,
  ): Promise<CollectionResponse<Project>> {
    const params = parseCriteria(criteria, {
      page,
      perPage,
    });

    if (this.fundId) {
      return ApiClient.get(`/api/funds/${this.fundId}/projects`, params);
    }
    return ApiClient.get('/api/projects', params);
  }
}

export default ProjectRepository;
