import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import UserRepository from './UserRepository';
import { User } from '../../types';
import TabPanel from '../../components/TabPanel';
import settings from '../../config/settings';
import { colors } from '../../config/theme';
import ChangePasswordForm from './ChangePasswordForm';
import TwoFactorConfiguration from './TwoFactorConfiguration';
import UserForm from './UserForm';
import Circle from '../../components/Circle';
import Loader from '../../components/Loader';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    marginTop: `calc(${theme.spacing(6)} + ${settings.header.height}px)`,
  },
  tab: {
    marginRight: theme.spacing(),
    background: colors.lightestGrey,
    transition: 'all 0.3s ease',
    '&[aria-selected="true"]': {
      background: '#FFF',
      boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.09)',
    },
    textTransform: 'none',
    fontWeight: 500,
  },
  tabpanel: {
    minWidth: '400px',
    width: '60%',
    display: 'flex',
    justifyContent: 'center',
    background: colors.white,
    boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.09)',
  },
  container: {
    position: 'relative',
    zIndex: 1,
  },
  section: {
    marginBottom: theme.spacing(6),
  },
  sectionHeading: {
    marginBottom: theme.spacing(3),
  },
}));

interface EditUserProps {
  id?: string;
}

const EditUser = (props: EditUserProps) => {
  const { id: passedId } = props;
  const repository = new UserRepository();
  const history = useHistory();
  const { id: paramId } = useParams<{ id?: string }>();
  const [user, setUser] = useState<User | null>(null);
  const classes = useStyles();

  const [tab, setTab] = useState<number>(0);

  const id = passedId || paramId || '';

  useEffect(() => {
    repository
      .find(id)
      .then((res) => setUser(res.data))
      .catch(() => history.push('/gebruikers'));
  }, []);

  const changeTab = (event: ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  if (!user) {
    return <Loader />;
  }

  return (
    <div>
      <Circle />

      <Container className={classes.content}>
        <Tabs value={tab} onChange={changeTab} aria-label="profile tabs">
          <Tab
            label="Profiel"
            id="profile-tab-0"
            aria-controls="profile-tabpanel-0"
            className={classes.tab}
          />
          <Tab
            label="Instellingen"
            id="profile-tab-1"
            aria-controls="profile-tabpanel-1"
            className={classes.tab}
          />
        </Tabs>

        <TabPanel value={tab} index={0} className={classes.tabpanel}>
          <UserForm user={user} />
        </TabPanel>
        <TabPanel value={tab} index={1} className={classes.tabpanel}>
          <Grid container className={classes.container} spacing={4}>
            <Grid item container xs={12} md={12}>
              <Grid item xs={12} className={classes.section}>
                <Typography
                  component="h2"
                  variant="h6"
                  className={classes.sectionHeading}
                >
                  Meervoudige authenticatie
                </Typography>
                {user && (
                  <Box mb={4}>
                    <TwoFactorConfiguration user={user} />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} className={classes.section}>
                <Typography
                  component="h2"
                  variant="h6"
                  className={classes.sectionHeading}
                >
                  Wachtwoord wijzigen
                </Typography>
                {user && <ChangePasswordForm user={user} />}
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
      </Container>
    </div>
  );
};

export default EditUser;
