import React, { useContext, useMemo, useState } from 'react';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useHistory } from 'react-router-dom';

import DataTable from '../../components/DataTable';
import UserRepository from './UserRepository';
import { User } from '../../types';
import ApiClient from '../../api/ApiClient';
import StatusIndicator from '../../components/StatusIndicator';
import SearchContext from '../../components/search/SearchContext';
import SearchInput from '../../components/search/SearchInput';
import UserImitateButton from './components/UserImitateButton';
import AppContext from '../../AppContext';
import PageContainer from '../../components/PageContainer';
import Roles, { RoleInterface } from './Roles';

const UsersOverview = () => {
  const history = useHistory();
  const notifications = useSnackbar();

  const [query, setQuery] = useState<string>('');
  const { roleViewManager } = useContext(AppContext);
  const repository = useMemo(() => new UserRepository(), []);

  const searchContextValue = useMemo(
    () => ({ query, setQuery }),
    [query, setQuery],
  );

  /**
   * Sends activation mail to user.
   */
  const handleSendActivationMail = (email: string) => {
    ApiClient.sendActivationMail(email)
      .then(() => {
        notifications.enqueueSnackbar(
          'De activatie mail is opnieuw verzonden.',
          { variant: 'success' },
        );
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het versturen van de activate mail.',
          { variant: 'error' },
        );
      });
  };

  const columns = [
    {
      name: 'Naam',
      field: 'lastName',
      sortable: true,
      render: (user: User) => {
        return (
          <>
            <StatusIndicator indicator={user.active ? 'green' : 'red'} />
            <Box ml={1}>{user.fullNameLastNameFirst}</Box>
          </>
        );
      },
    },
    {
      name: 'Rollen',
      field: 'roles',
      render: (user: User) => {
        return user.roles
          .map((role: keyof RoleInterface) => Roles[role])
          .join(', ');
      },
    },
  ];

  const itemActions = (user: User, className: string) => (
    <div>
      {(roleViewManager.isAdminView() ||
        roleViewManager.isCentralManagerView() ||
        roleViewManager.isFundManagerView()) &&
        !user.active && (
          <Tooltip title="Activatie mail versturen">
            <IconButton
              size="small"
              onClick={() => handleSendActivationMail(user.email)}
              className={className}
            >
              <FontAwesomeIcon icon={['fal', 'envelope']} />
            </IconButton>
          </Tooltip>
        )}
      {roleViewManager.isAdminView() && (
        <UserImitateButton user={user} className={className} />
      )}
      {(roleViewManager.isAdminView() ||
        roleViewManager.isCentralManagerView() ||
        roleViewManager.isFundManagerView()) && (
        <Tooltip title="Gebruiker bewerken">
          <IconButton
            size="small"
            component={Link}
            to={`/gebruikers/${user.id}/bewerken`}
            className={className}
          >
            <FontAwesomeIcon icon={['fal', 'edit']} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );

  const actions = (
    <Box display="flex" alignItems="center">
      <SearchInput id="user-search-input" persistQuery />
      <Box ml={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push('/gebruikers/aanmaken')}
          startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
        >
          Nieuwe gebruiker
        </Button>
      </Box>
    </Box>
  );

  return (
    <SearchContext.Provider value={searchContextValue}>
      <PageContainer title="Gebruikers" actions={actions}>
        <Box mt={3}>
          <DataTable
            id="users-overview"
            repository={repository}
            columns={columns}
            actions={itemActions}
            searchable
            deletable={
              roleViewManager.isAdminView() ||
              roleViewManager.isCentralManagerView() ||
              roleViewManager.isFundManagerView()
            }
            deleteItemMessage={(user: User) => {
              return `Weet je zeker dat je ${
                user ? user.fullName : 'deze gebruiker'
              } wilt verwijderen?`;
            }}
          />
        </Box>
      </PageContainer>
    </SearchContext.Provider>
  );
};

export default UsersOverview;
