import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import PageContainer from '../../components/PageContainer';
import ProjectRepository from './ProjectRepository';
import { SaveProjectDTO, projectToCreateProjectDTO } from '../../types';
import ProjectForm from './ProjectForm';
import Loader from '../../components/Loader';
import Circle from '../../components/Circle';

const EditProject = () => {
  const projectRepository = new ProjectRepository();
  const history = useHistory();

  const [project, setProject] = useState<SaveProjectDTO | undefined>(undefined);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    projectRepository
      .find(id)
      .then((res) => {
        setProject(projectToCreateProjectDTO(res.data));
      })
      .catch(() => history.push('/projecten'));
  }, []);

  if (!project) {
    return <Loader />;
  }

  return (
    <div>
      <Circle />
      <PageContainer title="Project bewerken">
        <ProjectForm project={project} />
      </PageContainer>
    </div>
  );
};

export default EditProject;
