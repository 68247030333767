import React, { PropsWithChildren, useEffect } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import background from './assets/images/login_background.jpg';
import { colors } from './config/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    minHeight: '100%',
    overflow: 'hidden',
  },
  container: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  backgroundGridItem: {
    position: 'absolute',
    width: '35vw',
    height: '100%',
    background: `url(${background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      opacity: 0.25,
      background: colors.blueZodiac,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
  content: {
    paddingTop: 100,
  },
  title: {
    fontWeight: 600,
    fontSize: 40,
    fontFamily: theme.typography.body1.fontFamily,
    marginBottom: theme.spacing(4),
  },
  description: {
    fontSize: 17,
  },
}));

const Home = (props: PropsWithChildren<any>) => {
  const classes = useStyles();

  useEffect(() => {
    document.body.classList.add('footer-no-margin');

    return () => document.body.classList.remove('footer-no-margin');
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.backgroundGridItem} />
      <div className={classes.container}>
        <div className={classes.content}>{props.children}</div>
      </div>
    </div>
  );
};

export default Home;
