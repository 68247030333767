import React from 'react';

import ErrorPage from './ErrorPage';

const Error500 = () => (
  <ErrorPage
    title="Oeps!"
    description="Er is iets fout gegaan. Probeer het later opnieuw."
  />
);

export default Error500;
