import { User } from './types';
import { RoleInterface } from './modules/users/Roles';

export type RoleView =
  | 'admin'
  | 'central-manager'
  | 'fund-manager'
  | 'fund-user';

/**
 * This class manages the role-specific views the user can view.
 */
class RoleViewManager {
  /**
   * The current loggedin user.
   * @private
   */
  private user: User;

  /**
   * The current view that the user selected.
   * @private
   */
  private currentView: RoleView;

  private readonly localStore: LocalForage | undefined;

  private readonly viewLabels: { [key in RoleView]: string } = {
    admin: 'Admin',
    'central-manager': 'Centraal manager',
    'fund-manager': 'Fondsmanager',
    'fund-user': 'Fondsmedewerker',
  };

  constructor(user: User, view?: RoleView, localStore?: LocalForage) {
    this.user = user;
    this.currentView = view || this.getViewForRole(this.user.roles[0]);
    this.localStore = localStore;
  }

  /**
   * Determine if the user is viewing as an admin.
   */
  isAdminView(): boolean {
    return this.user.roles.includes('ROLE_ADMIN');
  }

  /**
   * Determine if the user is viewing as a centralmanager.
   */
  isCentralManagerView(): boolean {
    return this.user.roles.includes('ROLE_CENTRAL_MANAGER');
  }

  /**
   * Determine if the user is viewing as a fundmanager.
   */
  isFundManagerView(): boolean {
    return this.user.roles.includes('ROLE_FUND_MANAGER');
  }

  /**
   * Determine if the user is viewing as a funduser.
   */
  isFundUserView(): boolean {
    return this.user.roles.includes('ROLE_FUND_USER');
  }

  /**
   * Determine if the user is an admin or imitating admin.
   */
  isAdminOrImitatingAdmin(): boolean {
    return this.isAdminView() || this.user.isImitatingAdmin === true;
  }

  /**
   * Get the current view.
   */
  getCurrentView(): RoleView {
    return this.currentView;
  }

  /**
   * Get all the role-specific views the user can view.
   */
  getViews(): RoleView[] {
    const views: RoleView[] = [];

    return views;
  }

  /**
   * Get label of the current selected view.
   */
  getViewLabel(view?: RoleView): string {
    if (view === undefined) {
      return this.viewLabels[this.currentView];
    }

    return this.viewLabels[view];
  }

  /**
   * @param view
   */
  async setCurrentView(view: RoleView): Promise<void | string | null> {
    this.currentView = view;

    if (this.localStore) {
      return this.localStore.setItem('view', view).catch(() => {});
    }

    return null;
  }

  /**
   * Set the current view from a specific role.
   * @param role
   */
  setCurrentViewFromRole(role: keyof RoleInterface): void {
    this.setCurrentView(this.getViewForRole(role));
  }

  /**
   * Determines if the current
   * @param role
   */
  currentViewSupportsRole(role: keyof RoleInterface) {
    return this.canAccessView(this.currentView, role);
  }

  canAccessView(
    view: RoleView,
    role: keyof RoleInterface | (keyof RoleInterface)[],
  ) {
    return role === 'ROLE_ADMIN';
  }

  /**
   * Determine if the user has any of the given roles.
   * @param role A role or array of roles to check for.
   */
  hasRole(role: keyof RoleInterface | (keyof RoleInterface)[]): boolean {
    if (this.user.roles.includes('ROLE_ADMIN')) {
      return true;
    }

    if (typeof role === 'string') {
      return this.user.roles.includes(role);
    }

    return this.user.roles.find((r) => role.includes(r)) !== undefined;
  }

  getUser(): User {
    return this.user;
  }

  async loadViewFromLocalStore(callback?: () => void) {
    if (!this.localStore) {
      return;
    }

    await this.localStore
      .getItem<RoleView>('view')
      .then((view) => {
        if (view && !this.canAccessView(view, this.user.roles)) {
          this.setCurrentView(this.getViewForRole(this.user.roles[0]));
          return;
        }

        if (view && view !== this.getCurrentView()) {
          this.setCurrentView(view);
        }
      })
      .finally(callback);
  }

  /**
   * Determine the view for a specific role.
   * @param role
   * @private
   */
  private getViewForRole(role: keyof RoleInterface): RoleView {
    switch (role) {
      case 'ROLE_ADMIN':
        return 'admin';
      case 'ROLE_CENTRAL_MANAGER':
        return 'central-manager';
      case 'ROLE_FUND_MANAGER':
        return 'fund-manager';
      case 'ROLE_FUND_USER':
        return 'fund-user';
      default:
        return 'fund-user';
    }
  }
}

export default RoleViewManager;
