import React from 'react';
import { Box, Container, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import background from './assets/images/footer.svg';
import { colors } from './config/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    flexShrink: 0,
    overflow: 'hidden',
    marginTop: theme.spacing(6),
    background: `url(${background})`,
    backgroundColor: colors.blueZodiac,
    backgroundSize: 'cover',
    backgroundPosition: 'right',
  },
  container: {
    position: 'relative',
    color: theme.palette.primary.contrastText,
    '& a': {
      color: theme.palette.primary.contrastText,
    },
  },
  circle: {
    position: 'absolute',
    overflow: 'hidden',
    width: 100,
    height: 100,
    top: -40,
    left: -40,
    border: `1px solid ${colors.shamrock}`,
    borderRadius: '50%',
    [theme.breakpoints.down('lg')]: {
      width: 75,
      height: 75,
    },
  },
  blockTitle: {
    fontWeight: 700,
    fontFamily: theme.typography.h2.fontFamily,
    marginBottom: theme.spacing(1),
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <div className={classes.circle} />
      <Container className={classes.container}>
        <Box py={4}>
          <Box className={classes.blockTitle}>Contact</Box>
          <Box mt={2}>
            <Typography variant="body1">
              <a href="mailto:info@achterstandsfondsen.nl">
                info@achterstandsfondsen.nl
              </a>
            </Typography>
          </Box>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
