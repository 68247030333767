import React from 'react';
import { Box, Typography } from '@mui/material';
import { User } from '../../types';
import TwoFactorConfiguration from './TwoFactorConfiguration';

interface TwoFactorWallProps {
  account: User;
}

const TwoFactorWall = (props: TwoFactorWallProps) => {
  const { account } = props;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      py={3}
    >
      <Box maxWidth={700}>
        <Box mb={2}>
          <Typography variant="h1">
            Inloggen met meervoudige verificatie
          </Typography>
        </Box>

        <Typography variant="body1">
          Om gegevens in deze omgeving beter te beschermen is meervoudige
          verificatie nodig om toegang te krijgen. Dat betekent dat je naast
          gebruikersnaam en wachtwoord een tweede keer je identiteit moet
          verifiëren. Je kiest in dit scherm op welke manier dit moet gebeuren.
        </Typography>

        <Box mt={2} mb={2}>
          <Typography variant="body1">
            Meervoudige verificatie door gebruik te maken van:
            <ul>
              <li>een authenticator app (veiligste optie)</li>
              <li>verificatie via e-mail</li>
            </ul>
          </Typography>
        </Box>

        <Box mt={2} mb={5}>
          <Typography variant="body1">
            Mocht je op een later moment van verificatiemethode willen
            veranderen dan vind je de instelling hiervoor onder je profiel.
          </Typography>
        </Box>

        <TwoFactorConfiguration user={account} defaultMethod="authenticator" />
      </Box>
    </Box>
  );
};

export default TwoFactorWall;
