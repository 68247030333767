import React, { FormEvent, useEffect, useState } from 'react';
import { Box, Button, Paper, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import PageContainer from '../../components/PageContainer';
import Circle from '../../components/Circle';
import { CategoryDTO } from '../../types';
import CategoryRepository from './CategoryRepository';
import { CATEGORY_OVERVIEW } from './index';
import Loader from '../../components/Loader';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

const SaveCategory = () => {
  const { id } = useParams<{ id?: string }>();
  const classes = useStyles();
  const notifications = useSnackbar();
  const history = useHistory();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [category, setCategory] = useState<CategoryDTO>({
    name: '',
    description: null,
  });

  const { name, description } = category;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategory({
      ...category,
      [e.target.name as keyof CategoryDTO]: e.target.value as any,
    });
  };

  const handleUpdate = (id: string) => {
    new CategoryRepository()
      .update(id, category)
      .then(() => {
        notifications.enqueueSnackbar('De categorie is succesvol bewerkt.', {
          variant: 'success',
        });
        history.push(CATEGORY_OVERVIEW);
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Fout bij het bewerken van de categorie.',
          { variant: 'error' },
        );
      });
  };

  const handleCreate = () => {
    new CategoryRepository()
      .create(category)
      .then(() => {
        notifications.enqueueSnackbar('De categorie is succesvol aangemaakt.', {
          variant: 'success',
        });
        history.push(CATEGORY_OVERVIEW);
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Fout bij het aanmaken van de categorie.',
          { variant: 'error' },
        );
      });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (id) {
      handleUpdate(id);
    } else {
      handleCreate();
    }
  };

  useEffect(() => {
    (async () => {
      if (id) {
        const response = await new CategoryRepository().find(id);
        setCategory(response.data);
      }

      setLoaded(true);
    })();
  }, [id]);

  if (!loaded) {
    return <Loader />;
  }

  return (
    <div>
      <Circle />
      <PageContainer title={id ? 'Categorie bewerken' : 'Category aanmaken'}>
        <Paper className={classes.paper}>
          <form onSubmit={handleSubmit}>
            <Box py={1}>
              <TextField
                label="Naam"
                onChange={handleInputChange}
                value={name}
                name="name"
                fullWidth
                required
              />
            </Box>
            <Box py={1}>
              <TextField
                label="Beschrijving"
                onChange={handleInputChange}
                name="description"
                minRows={3}
                value={description}
                multiline
                fullWidth
              />
            </Box>
            <Box py={1}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                startIcon={<FontAwesomeIcon icon={['fad', 'save']} />}
              >
                {id ? 'Bewerken' : 'Opslaan'}
              </Button>
            </Box>
          </form>
        </Paper>
      </PageContainer>
    </div>
  );
};

export default SaveCategory;
